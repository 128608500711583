// utils
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";
import { isValidEmailAddress } from "../../../../../../utils/validationUtils"

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_lime from "../../../../files/icons/rency/square_lime.svg";
import square_black from "../../../../../../files/icons/rency/square_black.svg";
import square_plum from "../../../../../../files/icons/rency/square_plum.svg";
import sw_competence from "../../../../files/images/pages/embedded/sw_competence.webp";
import cybersec_img from "../../../../files/images/pages/cybersec/cybersec.jpg";
import martin_mikulka_g_05 from "../../../../../../files/images/pages/contact/martin-mikulka-circle-g-05.webp";
import loading_animation from "../../../../../../files/animations/pulsing-stripes-divelit-colors.svg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldHalved, faUserShield, faVirus, faUserSecret, faWifi, faHistory, faEnvelope, faKey, faLock, faDesktop, faGlobeAmericas, faUser, faUserGraduate, faExclamationTriangle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import BtnBgPlum70Send from "../../../../../../components/btn/btn-bg-plum-70-send";

/**
 * TrainingReasons section
 * gives user a brief overview why he should train his employees in cybersec
 * @returns HTML code with TrainingReasons section for training bookmark on CYBERSEC page
 */
export default function TrainingReasons() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    
    return (
        <>  
            <Container>
                <Row>
                    <Col className="mt-5">
                        <div className="d-flex flex-row justify-content-center align-items-center scroll-anim-750">
                            <h4 className="w-fit-content">{translation.header}</h4>
                            <FontAwesomeIcon icon={faQuestionCircle} aria-hidden="true" className="font-divelit-plum ms-3 w-fit-content" style={{height: "7rem"}}/>
                        </div>
                    </Col>
                </Row>
                <Row className="">
                    <Col md={2}></Col>
                    <Col className="mt-3 mt-md-5 scroll-anim-1000">
                        <Row className="d-flex flex-row align-items-center">
                            <img src={square_plum} className="home-experts-img w-fit-content" alt={translation.alt.square_plum}/>
                            <h4 className="mb-0 w-fit-content font-divelit-plum">01</h4>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <div className="w-fit-content">
                                    <h5 className="mt-3 mt-lg-4 mb-0 font-divelit-plum">
                                    {translation.reasons.r_01.header}
                                    </h5>
                                    <p className="paragraph-medium mt-3">
                                    {translation.reasons.r_01.info}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}></Col>
                </Row>
                <Row>
                <Col md={2}></Col>
                    <Col className="ms-3 ms-md-5 mt-3 mt-md-5 scroll-anim-1000">
                        <Row className="d-flex flex-row align-items-center">
                            <img src={square_black} className="home-experts-img w-fit-content" alt={translation.alt.square_black}/>
                            <h4 className="mb-0 w-fit-content">02</h4>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <div className="w-fit-content">
                                    <h5 className="mt-3 mt-lg-4 mb-0">
                                    {translation.reasons.r_02.header}
                                    </h5>
                                    <p className="paragraph-medium mt-3">
                                    {translation.reasons.r_02.info}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}></Col>
                </Row>
                <Row>
                    <Col md={2}></Col>
                    <Col className="mt-3 mt-md-5 scroll-anim-1000">
                        <Row className="d-flex flex-row align-items-center">
                            <img src={square_plum} className="home-experts-img w-fit-content" alt={translation.alt.square_plum}/>
                            <h4 className="mb-0 w-fit-content font-divelit-plum" >03</h4>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <div className="w-fit-content">
                                    <h5 className="mt-3 mt-lg-4 mb-0 font-divelit-plum">
                                    {translation.reasons.r_03.header}
                                    </h5>
                                    <p className="paragraph-medium mt-3">
                                    {translation.reasons.r_03.info}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}></Col>
                </Row>
                <Row>
                    <Col md={2}></Col>
                    <Col className="ms-3 ms-md-5 mt-3 mt-md-5 scroll-anim-1000">
                        <Row className="d-flex flex-row align-items-center">
                            <img src={square_black} className="home-experts-img w-fit-content" alt={translation.alt.square_black}/>
                            <h4 className="mb-0 w-fit-content">04</h4>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <div className="w-fit-content">
                                    <h5 className="mt-3 mt-lg-4 mb-0">
                                    {translation.reasons.r_04.header}
                                    </h5>
                                    <p className="paragraph-medium mt-3">
                                    {translation.reasons.r_04.info}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}></Col>
                </Row>
                <Row>
                    <Col md={2}></Col>
                    <Col className="mt-3 mt-md-5 scroll-anim-1000">
                        <Row className="d-flex flex-row align-items-center">
                            <img src={square_plum} className="home-experts-img w-fit-content" alt={translation.alt.square_plum}/>
                            <h4 className="mb-0 w-fit-content font-divelit-plum" >05</h4>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <div className="w-fit-content">
                                    <h5 className="mt-3 mt-lg-4 mb-0 font-divelit-plum">
                                    {translation.reasons.r_05.header}
                                    </h5>
                                    <p className="paragraph-medium mt-3">
                                    {translation.reasons.r_05.info}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}></Col>
                </Row>
                <Row>
                    <Col md={2}></Col>
                    <Col className="ms-3 ms-md-5 mt-3 mt-md-5 scroll-anim-1000">
                        <Row className="d-flex flex-row align-items-center">
                            <img src={square_black} className="home-experts-img w-fit-content" alt={translation.alt.square_black}/>
                            <h4 className="mb-0 w-fit-content">06</h4>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12}>
                                <div className="w-fit-content">
                                    <h5 className="mt-3 mt-lg-4 mb-0">
                                    {translation.reasons.r_06.header}
                                    </h5>
                                    <p className="paragraph-medium mt-3">
                                    {translation.reasons.r_06.info}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}></Col>
                </Row>
            </Container>
        </>
    );
}

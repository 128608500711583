// utils
import React from "react";
import { useMatch, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";


// custom utils
import { getCurrentLanguage, getUrlPrefix, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_white from "../../../../../../files/icons/rency/square_white.svg";
import square_black from "../../../../../../files/icons/rency/square_black.svg";
import cybersec_img_2 from "../../../../../../files/images/pages/cybersec/IMG_5726.jpeg";
import cybersec_img_3 from "../../../../../../files/images/pages/cybersec/server_room_2.webp";

/**
 * ServicesCybersec section
 * composed from representative photo of our cybersec product with some text information
 * @returns HTML code with ServicesCybersec section used for HomeServices section on HOME page
 */
export default function ServicesCybersec() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    return (
        <>  
            
            {/*<Container style={{display: "none"}}>
                <Row className="mt-3 mt-lg-5 scroll-anim-1000 blog-older-container blog-older-container-me" style={{height:"fit-content"}}>
                    <Col xs={12} sm={12} className="pe-0 ps-0 order-1 order-lg-2 bg-divelit-grey-10 text-center blog-older-container-ms-img-border" style={{height: "auto"}}>
                        <Link className="font-white text-start" to={getUrlPrefix(language) + "cybersec"} style={{textDecoration: "none", color: "var(--divelit-grey-05)"}}>
                            <div className="blog-older-img-thumbnail-test project-tile-border">
                                <div className="d-flex flex-column thumbnail">
                                    <p className="mt-auto mb-auto paragraph-medium blog-latest-img-btn align-self-center bg-divelit-plum">{translation.btn_read_more}<img className="button-arrow"></img></p>
                                </div>
                                <div className="content">
                                    <Row className="m-0">
                                        <Col xs={12} sm={12} lg={8} xl={8} xxl={8} className="ps-0 pe-0 order-2 order-lg-1 blog-older-container-ms-text-border bg-divelit-plum h-auto" style={{zIndex: "2"}}>
                                            <div className="mt-3 mt-lg-5 me-3 me-lg-5 mb-2 mb-lg-5 ms-3 ms-lg-5">
                                                <Row className="d-flex flex-row align-items-center">
                                                    <img src={square_white} className="home-experts-img w-fit-content" alt={translation.alt.square_black}/>
                                                    <h4 className="mb-0 w-fit-content" >03</h4>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12}>
                                                        <div className="w-fit-content">
                                                            <h5 className="mt-3 mt-lg-5 mb-0">
                                                            {translation.header}
                                                            </h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="">
                                                    <Col xs={12} sm={12} className="">
                                                        <p className="mt-2 mt-lg-3 paragraph-small">
                                                        {translation.paragraph}
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} lg={4} xl={4} xxl={4} className="pe-0 ps-0 order-1 order-lg-2 bg-divelit-grey-10 blog-older-container-ms-img-border h-auto">
                                            <div className="d-flex flex-row align-items-center justify-content-center h-100">
                                                <img className="pe-0 home-services-img" alt={translation.alt.embd_img} src={cybersec_img}/>
                                            </div>
                                            <div className="home-services-img-plum-arrow">

                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>*/}






            {/*
                TODO : 
                60 barva je ok 1:7,4 - tahle by stačila (když snížíme odstín těch prvních kontejnerů aby to líp pasovalo)
                70 barva bych dal max 1:5,98 - vypadá plnější
                80 barva má ještě 1:4,75
            */}
            {/*<Container>
                <Row className="mt-3 mt-lg-5 scroll-anim-1000 blog-older-container blog-older-container-me" style={{height:"fit-content"}}>
                    <Col xs={12} sm={12} className="pe-0 ps-0 order-1 order-lg-2 bg-divelit-grey-10 text-center blog-older-container-ms-img-border" style={{height: "auto"}}>
                        <Link className="font-black text-start" to={getUrlPrefix(language) + "cybersec"} style={{textDecoration: "none"}}>
                            <div className="blog-older-img-thumbnail-test project-tile-border">
                                <div className="d-flex flex-column thumbnail">
                                    <p className="mt-auto mb-auto paragraph-medium blog-latest-img-btn align-self-center bg-divelit-plum-70">{translation.btn_read_more}<img className="button-arrow"></img></p>
                                </div>
                                <div className="content">
                                    <Row className="m-0">
                                        <Col xs={12} sm={12} lg={8} xl={8} xxl={8} className="ps-0 pe-0 order-2 order-lg-1 blog-older-container-ms-text-border bg-divelit-plum-70 h-auto" style={{zIndex: "2"}}>
                                            <div className="mt-3 mt-lg-5 me-3 me-lg-5 mb-2 mb-lg-5 ms-3 ms-lg-5">
                                                <Row className="d-flex flex-row align-items-center">
                                                    <img src={square_black} className="home-experts-img w-fit-content" alt={translation.alt.square_black}/>
                                                    <h4 className="mb-0 w-fit-content" >03</h4>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12}>
                                                        <div className="w-fit-content">
                                                            <h5 className="mt-3 mt-lg-5 mb-0">
                                                            {translation.header}
                                                            </h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="">
                                                    <Col xs={12} sm={12} className="">
                                                        <p className="mt-2 mt-lg-3 paragraph-small">
                                                        {translation.paragraph}
                                                        </p>
                                                    </Col>
                                                </Row>

                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} lg={4} xl={4} xxl={4} className="pe-0 ps-0 order-1 order-lg-2 bg-divelit-grey-10 blog-older-container-ms-img-border h-auto">
                                            <div className="d-flex flex-row align-items-center justify-content-center h-100">
                                                <img className="pe-0 home-services-img home-services-cybersec-img" alt={translation.alt.embd_img} src={cybersec_img}/>
                                            </div>
                                            <div className="home-services-img-plum-70-arrow">

                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>*/}


            <Container>
                <Row className="mt-3 mt-lg-5 scroll-anim-1000 blog-older-container blog-older-container-me" style={{height:"fit-content"}}>
                    <Col xs={12} sm={12} className="pe-0 ps-0 order-1 order-lg-2 bg-divelit-grey-10 text-center blog-older-container-ms-img-border" style={{height: "auto"}}>
                        <Link className="font-black text-start" to={getUrlPrefix(language) + "cybersec"} style={{textDecoration: "none"}}>
                            <div className="blog-older-img-thumbnail-test project-tile-border">
                                <div className="d-flex flex-column thumbnail">
                                    <p className="mt-auto mb-auto paragraph-medium blog-latest-img-btn align-self-center bg-divelit-plum-70">{translation.btn_read_more}<img className="button-arrow"></img></p>
                                </div>
                                <div className="content">
                                    {/*<img className="pe-0 blog-latest-img" alt={translation.alt.embd_img} src={embedded_img} />*/}
                                    <Row className="m-0">
                                        {/*
                                            TODO : 
                                            60 barva je ok 1:7,4 - tahle by stačila (když snížíme odstín těch prvních kontejnerů aby to líp pasovalo)
                                            70 barva bych dal max 1:5,98 - vypadá plnější
                                            80 barva má ještě 1:4,75
                                        */}
                                        <Col xs={12} sm={12} lg={8} xl={8} xxl={8} className="ps-0 pe-0 order-2 order-lg-1 blog-older-container-ms-text-border bg-divelit-plum-70 h-auto" style={{zIndex: "2"}}>
                                            <div className="mt-3 mt-lg-5 me-3 me-lg-5 mb-2 mb-lg-5 ms-3 ms-lg-5">
                                                <Row className="d-flex flex-row align-items-center">
                                                    <img src={square_black} className="home-experts-img w-fit-content" alt={translation.alt.square_black}/>
                                                    <h4 className="mb-0 w-fit-content" >03</h4>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12}>
                                                        <div className="w-fit-content">
                                                            <h5 className="mt-3 mt-lg-5 mb-0">
                                                            {translation.header}
                                                            </h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="">
                                                    <Col xs={12} sm={12} className="">
                                                        <p className="mt-2 mt-lg-3 paragraph-small">
                                                        {translation.paragraph}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                {/*<Row className="">
                                                    <Col xs={12} sm={12} className="pt-2 pt-lg-5">
                                                        <a href={getUrlPrefix(language) + "embedded"} className="mt-auto mb-0 button-w-border bg-divelit-lemon paragraph-small scroll-anim-1000">{translation.btn_read_more}<img className="button-arrow"></img></a>                                     
                                                    </Col>
                                                </Row>*/}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} lg={4} xl={4} xxl={4} className="pe-0 ps-0 order-1 order-lg-2 bg-divelit-grey-10 blog-older-container-ms-img-border h-auto">
                                            <div className="d-flex flex-row align-items-center justify-content-center h-100">
                                                <img className="pe-0 home-services-img home-services-cybersec-img" alt={translation.alt.embd_img} src={cybersec_img_3}/>
                                            </div>
                                            <div className="home-services-img-plum-70-arrow">

                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

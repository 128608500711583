// utils
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";
import { isValidEmailAddress } from "../../../../utils/validationUtils"

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import loading_animation from "../../../../files/animations/pulsing-stripes-divelit-colors.svg";
import BtnBgLemonSend from "../../../../components/btn/btn-bg-lemon-send";

/**
 * ContactForm section
 * Handles CONTACT page form - language, required input check, POST to the server as multipart/form-data
 * @returns HTML code with ContactForm section for CONTACT page
 */
export default function ContactForm() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    // Default form fields values with functions to change them
    const [inputMsg, setInputMsg] = useState("");
    const [inputName, setInputName] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [inputCompany, setInputCompany] = useState("");
    const [inputPhone, setInputPhone] = useState("");
    const [checkBoxConsent, setCheckBoxConsent] = useState(false);
    const [formMsg, setFormMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorMsg, setIsErrorMsg] = useState(false);

    /**
     * On form submit is being checked whether user filled all the required fields or not and if given email is valid
     * @returns if POST is successfull GREEN message is displayed and all fields are deleted
     * @returns if POST was not successfull RED message is displayed and input remains untouched 
     */
    const handleSubmitForm = () => {
        // all <form> data to POST will be stored in this variable in multipart/form-data format
        const data = new FormData();

        console.log(inputMsg,inputName,inputEmail,inputCompany,inputPhone,checkBoxConsent)
        
        if(inputName === "")
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.name_error)
            return
        }
        if(inputEmail === "")
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.email_missing_error)
            return
        }
        else if(!isValidEmailAddress(inputEmail))
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.email_invalid_error)
            return
        }
        if(inputMsg === "")
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.empty_msg)
            return
        }
        if(!checkBoxConsent)
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.consent_error)
            return
        }
        setFormMsg("")
        setIsLoading(true);



        // adding all other inputs into 'data'
        data.append("contact_name", inputName);
        data.append("contact_email", inputEmail);
        data.append("contact_company", inputCompany);
        data.append("contact_phone", inputPhone);
        data.append("contact_msg", inputMsg);

        // if SUCCESS delete all inputs (otherwise let be)
        axios.post("/api/contact/form", data, {
            headers: {
                "Content-type": "multipart/form-data"
            },
            params: {
                lang: language
            }
          })
          .then(function (response) {
            setIsLoading(false);
            setIsErrorMsg(false);
            setFormMsg(translation.form.form_send_success);
            console.log(response);
            
            setInputMsg("");
            setInputName("");
            setInputEmail("");
            setInputCompany("");
            setInputPhone("");
            setCheckBoxConsent(false);

        })
        .catch(function (error) {
            setIsLoading(false);
            setIsErrorMsg(true);
            setFormMsg(translation.form.form_send_error);
            console.log(error);
        });
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <div className="">
                <div className="bg-divelit-grey-05 navbar-size-compensation">
                    <div className="pt-3 pt-lg-5"></div>
                    <Container className="pt-3 pt-lg-5 pb-3 pb-lg-5">
                        <Row className="row">
                            <Col xs={12} sm={12} md={6} className="">
                                <div className="fade-in-750-down-to-top">
                                    <h2 className="">{translation.header.part1}</h2>
                                    <h2 className="">{translation.header.part2}</h2>
                                    <h2 className="">{translation.header.part3}</h2>
                                    {/*<hr className="col-4 col-md-5 col-lg-6 jumbotron-hr" style={{marginTop: '0.5em'}}/>*/}
                                </div>
                                <div className="fade-in-1000-down-to-top">
                                    <p className="mt-1 mt-md-3 mt-lg-5 paragraph-small">{translation.main_paragraph}</p>

                                </div>
                                <div className="fade-in-1250-down-to-top">
                                    <p className="mt-1 mt-md-3 mt-lg-5 paragraph-small">{translation.write_email}
                                        <a className="link-href" href="mailto:project@divelit.cz">project@divelit.cz</a>
                                    </p>
                                </div>

                            </Col>
                            <Col xs={12} sm={12} md={6} className="">
                                <form id="contact_form" className="paragraph-small" method="post" action="" encType="multipart/form-data">
                                    <Row className="fade-in-750-down-to-top">
                                        <Col xs={12} sm={12} lg={6} className="">
                                            <input className="form-input-style" id="contact_name" type="text"
                                                name="contact_name" placeholder={translation.form.contact_name}
                                                value={inputName}
                                                onChange={(e) => setInputName(e.target.value)}/>
                                        </Col>
                                        <Col xs={12} sm={12} lg={6} className="">
                                            <input className="mt-4 mt-lg-0 form-input-style" id="contact_email" type="text"
                                                name="contact_email" placeholder={translation.form.contact_email}
                                                value={inputEmail}
                                                onChange={(e) => setInputEmail(e.target.value)}/>
                                        </Col>
                                    </Row>
                                    <Row className="fade-in-1000-down-to-top">
                                        <Col xs={12} sm={12} lg={6} className="mt-4 mt-md-4">
                                            <input className="form-input-style" id="contact_phone" type="text"
                                                name="contact_phone" placeholder={translation.form.contact_phone}
                                                value={inputPhone}
                                                onChange={(e) => setInputPhone(e.target.value)}/>
                                        </Col>
                                        <Col xs={12} sm={12} lg={6} className="mt-4 mt-md-4">
                                            <input className="form-input-style" id="contact_company"
                                                type="text" name="contact_company" placeholder={translation.form.contact_company}
                                                value={inputCompany}
                                                onChange={(e) => setInputCompany(e.target.value)}/>
                                        </Col>
                                    </Row>
                                    <Row className="fade-in-1250-down-to-top">
                                        <Col xs={12} sm={12} lg={12} className="mt-4 mt-md-4">
                                            <textarea className="form-msg-input-style contact-form-textarea" id="contact_msg"
                                            name="contact_msg" placeholder={translation.form.contact_text}
                                                    value={inputMsg}
                                                    onChange={(e) => setInputMsg(e.target.value)}/>
                                        </Col>
                                    </Row>
                                    <Row className="fade-in-1500-down-to-top">
                                        <Col xs={12} sm={12} lg={12} className="mt-4 mt-md-4">
                                            {/*&ensp;<label htmlFor='consent' className="checkbox-container-lemon">{translation.form.consent.part1}
                                                <input type="checkbox" name="consent"
                                                    checked={checkBoxConsent}
                                                    onChange={(e) => setCheckBoxConsent(e.target.checked)}/><span className="checkmark-lemon"></span>
                                                    
                                                 <a className="link-href" href={process.env.PUBLIC_URL + '/pdf/GDPR.pdf'} target="_blank"  rel="noreferrer">{translation.form.consent.part2}</a>
                                            {translation.form.consent.part3}</label>*/}
                                            <div className="d-flex flex-row">
                                                <label className="checkbox-container-lemon">
                                                    <input type="checkbox" name="consent"
                                                    checked={checkBoxConsent}
                                                    onChange={(e) => setCheckBoxConsent(e.target.checked)}/>
                                                    <span className="checkmark-lemon"></span>
                                                </label>
                                                <div className="" style={{lineHeight: "2rem"}}>
                                                    {translation.form.consent.part1}<a className="link-href" href={process.env.PUBLIC_URL + '/pdf/' + translation.form.gdpr} target="_blank"  rel="noreferrer">{translation.form.consent.part2}</a><span className="paragraph-small">{translation.form.consent.part3}</span>
                                                </div>                                                    
                                            </div>                                            
                                            <p className="mt-4 paragraph-extra-small">{translation.form.required}</p>
                                        </Col>
                                    </Row>
                                    <Row className="fade-in-1750-down-to-top">
                                        <Col xs={"auto"} sm={"auto"} className="mt-3 mt-md-3">
                                            {/*<a onClick={handleSubmitForm} className="button paragraph-small" style={{}}>{translation.form.send_btn}<img className="button-arrow"></img></a>*/}
                                            <BtnBgLemonSend onClick={handleSubmitForm}></BtnBgLemonSend>
                                        </Col>
                                        <Col xs={"auto"} sm={"auto"} className="mt-3 mt-md-3">
                                            <div className="form-message-box">
                                                {isLoading ?
                                                    <img src={loading_animation}
                                                        className="form-loading-animation" alt={translation.alt.loading_animation}/>
                                                    :
                                                    <span style={{color: isErrorMsg ? "red" : "green"}}>{formMsg}</span>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

// utils
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faMap, faChartLine, faArrowUpWideShort, faFile, faLegal, faSitemap, faMicrochip, faUsers, faUserGraduate, faExclamationTriangle, faQuestionCircle, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_black from "../../../../../../files/icons/rency/square_black.svg";
import square_plum from "../../../../../../files/icons/rency/square_plum.svg";
import square_divelit_grey_50 from "../../../../../../files/icons/rency/square_divelit_grey_50.svg";
import square_divelit_grey_70 from "../../../../../../files/icons/rency/square_divelit_grey_70.svg";
import BtnBgLemonContact from "../../../../../../components/btn/btn-bg-lemon-contact";
import BtnBgPlum70Contact from "../../../../../../components/btn/btn-bg-plum-70-contact";


/**
 * ManagementCollaboration section
 * gives user a brief overview about different phases of our cooperation
 * @returns HTML code with ManagementCollaboration section for management bookmark on CYBERSEC page
 */
export default function ManagementCollaboration() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    
    return (
        <>
            <Container className="mb-5">
                <Row>
                    <Col className="mt-5">
                        <div className="d-flex flex-row justify-content-center align-items-center scroll-anim-750">
                            <h4 className="w-fit-content">{translation.header}</h4>
                            <FontAwesomeIcon icon={faQuestionCircle} aria-hidden="true" className="font-divelit-plum ms-3 w-fit-content" style={{height: "7rem"}}/>
                        </div>
                    </Col>
                </Row>
                <div className="scroll-anim-1000 pb-4">

                    <Slide autoplay={false} onChange={function noRefCheck(){}} onStartChange={function noRefCheck(){}} indicators={true}>
                        <div className="each-slide-effect">
                            <div className="bg-white pt-5 mb-5">
                                <Container className="pt-0">
                                    <Row className="bg-white project-tile-box-shadow-divelit-grey-10 project-tile project-tile-border ms-5 me-5 mt-5 p-3 p-xl-5 p-lg-4"  style={{borderTop: "12px solid var(--divelit-plum)"}}>
                                        <Col xs={12} sm={12} lg={12} xxl={12} className="order-2 order-lg-1 p-0">
                                            <Row className="d-flex flex-row align-items-center">
                                                {/*<img src={square_lemon} className="project-square-img w-fit-content mb-2" alt={translation.alt.square_lemon}/>*/}
                                                {/*<Link className="hover-decoration-none w-fit-content" to={getUrlPrefix(language) + "our-work" + "/" + "linet-service-app"}>*/}
                                                    <h4 className="font-black w-fit-content mb-0">
                                                        {translation.cards.no_1.header}
                                                    </h4>
                                                {/*</Link>*/}
                                            </Row>
                                            {/*<Row className="">
                                                <Col className="">
                                                    <p className="paragraph-small mt-3 mt-xl-4 mb-0">
                                                        {translation.cards.no_1.paragraph}
                                                    </p>
                                                </Col>
                                            </Row>*/}
                                            <Row className="ms-m ms-md-3 mt-3 mt-lg-4 mt-xl-4 mt-xxl-4">
                                                <div className="d-flex flex-column">
                                                    <div className="flex-xl-grow-1">
                                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={square_divelit_grey_50} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_grey}/>
                                                                <h5 className="font-divelit-grey-50 align-self-center-md w-fit-content mb-0 ms-3">
                                                                    {translation.cards.no_1.employees}
                                                                </h5>
                                                            </div>
                                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                                {translation.cards.no_1.employees_data}

                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex-xl-grow-1 mt-3">
                                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={square_divelit_grey_70} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_grey}/>
                                                                <h5 className="font-divelit-grey-70 align-self-center-md w-fit-content mb-0 ms-3">
                                                                    {translation.cards.no_1.turnover}
                                                                </h5>
                                                            </div>
                                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                                {translation.cards.no_1.turnover_data}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={square_black} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_black}/>                                         
                                                                <h5 className="font-black align-self-center-md w-fit-content mb-0 ms-3">
                                                                    {translation.cards.no_1.focus}
                                                                </h5>
                                                            </div>
                                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                                {translation.cards.no_1.focus_data}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className="mt-4 mt-md-5 mt-lg-5">
                                                <Col xs={12} sm={12}>
                                                    <h5>{translation.cards.no_1.collab.header}</h5>
                                                </Col>
                                            </Row>
                                            {/*<Row>
                                                <Col xs={12} sm={12} md={3} xl={2} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-row align-items-center">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum">01</h4>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={9} xl={10} className="mt-3 mt-lg-4">
                                                    <p className="paragraph-medium mb-0">
                                                        {translation.cards.no_1.collab.no_1}
                                                    </p>
                                                </Col>
                                            </Row>*/}
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-3">01</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_1.collab.no_1}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-2">02</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_1.collab.no_2}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-2">03</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_1.collab.no_3}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-2">04</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_1.collab.no_4}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-2">05</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_1.collab.no_5}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4 mt-md-5 mt-lg-5 text-center">
                                                <Col xs={12} sm={12}>
                                                    <h5 className="mt-1 mt-md-3 mt-lg-4">{translation.cta}</h5>
                                                </Col>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-5">
                                                    <BtnBgPlum70Contact></BtnBgPlum70Contact>
                                                    
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Container>
                            </div>
                        </div>



                        <div className="each-slide-effect">
                            <div className="bg-white pt-5 mb-5">
                                <Container className="pt-0">
                                    <Row className="bg-white project-tile-box-shadow-divelit-grey-10 project-tile project-tile-border ms-5 me-5 mt-5 p-3 p-xl-5 p-lg-4"  style={{borderTop: "12px solid var(--divelit-plum)"}}>
                                        <Col xs={12} sm={12} lg={12} xxl={12} className="order-2 order-lg-1 p-0">
                                            <Row className="d-flex flex-row align-items-center">
                                                {/*<img src={square_lemon} className="project-square-img w-fit-content mb-2" alt={translation.alt.square_lemon}/>*/}
                                                {/*<Link className="hover-decoration-none w-fit-content" to={getUrlPrefix(language) + "our-work" + "/" + "linet-service-app"}>*/}
                                                    <h4 className="font-black w-fit-content mb-0">
                                                        {translation.cards.no_2.header}
                                                    </h4>
                                                {/*</Link>*/}
                                            </Row>
                                            {/*<Row className="">
                                                <Col className="">
                                                    <p className="paragraph-small mt-3 mt-xl-4 mb-0">
                                                        {translation.cards.no_2.paragraph}
                                                    </p>
                                                </Col>
                                            </Row>*/}
                                            <Row className="ms-m ms-md-3 mt-3 mt-lg-4 mt-xl-4 mt-xxl-4">
                                                <div className="d-flex flex-column">
                                                    <div className="flex-xl-grow-1">
                                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={square_divelit_grey_50} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_grey}/>
                                                                <h5 className="font-divelit-grey-50 align-self-center-md w-fit-content mb-0 ms-3">
                                                                    {translation.cards.no_2.employees}
                                                                </h5>
                                                            </div>
                                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                                {translation.cards.no_2.employees_data}

                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex-xl-grow-1 mt-3">
                                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={square_divelit_grey_70} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_grey}/>
                                                                <h5 className="font-divelit-grey-70 align-self-center-md w-fit-content mb-0 ms-3">
                                                                    {translation.cards.no_2.turnover}
                                                                </h5>
                                                            </div>
                                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                                {translation.cards.no_2.turnover_data}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={square_black} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_black}/>                                         
                                                                <h5 className="font-black align-self-center-md w-fit-content mb-0 ms-3">
                                                                    {translation.cards.no_2.focus}
                                                                </h5>
                                                            </div>
                                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                                {translation.cards.no_2.focus_data}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className="mt-4 mt-md-5 mt-lg-5">
                                                <Col xs={12} sm={12}>
                                                    <h5>{translation.cards.no_2.collab.header}</h5>
                                                </Col>
                                            </Row>
                                            {/*<Row>
                                                <Col xs={12} sm={12} md={3} xl={2} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-row align-items-center">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum">01</h4>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={9} xl={10} className="mt-3 mt-lg-4">
                                                    <p className="paragraph-medium mb-0">
                                                        {translation.cards.no_1.collab.no_1}
                                                    </p>
                                                </Col>
                                            </Row>*/}
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-3">01</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_2.collab.no_1}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-2">02</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_2.collab.no_2}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-2">03</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_2.collab.no_3}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-2">04</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_2.collab.no_4}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-2">05</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_2.collab.no_5}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4 mt-md-5 mt-lg-5 text-center">
                                                <Col xs={12} sm={12}>
                                                    <h5 className="mt-1 mt-md-3 mt-lg-4">{translation.cta}</h5>
                                                </Col>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-5">
                                                    <BtnBgPlum70Contact></BtnBgPlum70Contact>
                                                    
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Container>
                            </div>
                        </div>


                        <div className="each-slide-effect">
                            <div className="bg-white pt-5 mb-5">
                                <Container className="pt-0">
                                    <Row className="bg-white project-tile-box-shadow-divelit-grey-10 project-tile project-tile-border ms-5 me-5 mt-5 p-3 p-xl-5 p-lg-4"  style={{borderTop: "12px solid var(--divelit-plum)"}}>
                                        <Col xs={12} sm={12} lg={12} xxl={12} className="order-2 order-lg-1 p-0">
                                            <Row className="d-flex flex-row align-items-center">
                                                {/*<img src={square_lemon} className="project-square-img w-fit-content mb-2" alt={translation.alt.square_lemon}/>*/}
                                                {/*<Link className="hover-decoration-none w-fit-content" to={getUrlPrefix(language) + "our-work" + "/" + "linet-service-app"}>*/}
                                                    <h4 className="font-black w-fit-content mb-0">
                                                        {translation.cards.no_3.header}
                                                    </h4>
                                                {/*</Link>*/}
                                            </Row>
                                            {/*<Row className="">
                                                <Col className="">
                                                    <p className="paragraph-small mt-3 mt-xl-4 mb-0">
                                                        {translation.cards.no_3.paragraph}
                                                    </p>
                                                </Col>
                                            </Row>*/}
                                            <Row className="ms-m ms-md-3 mt-3 mt-lg-4 mt-xl-4 mt-xxl-4">
                                                <div className="d-flex flex-column">
                                                    <div className="flex-xl-grow-1">
                                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={square_divelit_grey_50} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_grey}/>
                                                                <h5 className="font-divelit-grey-50 align-self-center-md w-fit-content mb-0 ms-3">
                                                                    {translation.cards.no_3.employees}
                                                                </h5>
                                                            </div>
                                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                                {translation.cards.no_3.employees_data}

                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex-xl-grow-1 mt-3">
                                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={square_divelit_grey_70} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_grey}/>
                                                                <h5 className="font-divelit-grey-70 align-self-center-md w-fit-content mb-0 ms-3">
                                                                    {translation.cards.no_3.turnover}
                                                                </h5>
                                                            </div>
                                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                                {translation.cards.no_3.turnover_data}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="mt-3">
                                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                                            <div className="d-flex flex-row align-items-center">
                                                                <img src={square_black} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_black}/>                                         
                                                                <h5 className="font-black align-self-center-md w-fit-content mb-0 ms-3">
                                                                    {translation.cards.no_3.focus}
                                                                </h5>
                                                            </div>
                                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                                {translation.cards.no_3.focus_data}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Row>
                                            <Row className="mt-4 mt-md-5 mt-lg-5">
                                                <Col xs={12} sm={12}>
                                                    <h5>{translation.cards.no_3.collab.header}</h5>
                                                </Col>
                                            </Row>
                                            {/*<Row>
                                                <Col xs={12} sm={12} md={3} xl={2} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-row align-items-center">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum">01</h4>
                                                    </div>
                                                </Col>
                                                <Col xs={12} sm={12} md={9} xl={10} className="mt-3 mt-lg-4">
                                                    <p className="paragraph-medium mb-0">
                                                        {translation.cards.no_1.collab.no_1}
                                                    </p>
                                                </Col>
                                            </Row>*/}
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-3">01</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_3.collab.no_1}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-2">02</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_3.collab.no_2}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-2">03</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_3.collab.no_3}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-4">
                                                    <div className="ms-0 ms-md-4 d-flex flex-column flex-md-row">
                                                        <img src={square_plum} className="home-experts-img-1 w-fit-content d-none"></img>
                                                        <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum me-md-2">04</h4>
                                                    <p className="paragraph-medium mb-0 ms-3">
                                                        {translation.cards.no_3.collab.no_4}
                                                    </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4 mt-md-5 mt-lg-5 text-center">
                                                <Col xs={12} sm={12}>
                                                    <h5 className="mt-1 mt-md-3 mt-lg-4">{translation.cta}</h5>
                                                </Col>
                                                <Col xs={12} sm={12} className="mt-3 mt-lg-5">
                                                    <BtnBgPlum70Contact></BtnBgPlum70Contact>
                                                    
                                                </Col>
                                            </Row>
                                        </Col>

                                    </Row>
                                </Container>
                            </div>
                        </div>





                        {/*<div className="each-slide-effect">
                            <div style={{ }}>
                                <span>Slide 2</span>
                            </div>
                        </div>
                        <div className="each-slide-effect">
                            <div style={{ }}>
                                <span>Slide 3</span>
                            </div>
                        </div>*/}
                    </Slide>
                </div>
            </Container>
        </>
    );
}

// utils
import React from "react";
import { useMatch } from "react-router-dom";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

/**
 * BtnBgPlum70Send component
 * contains BtnBgPlum70Send component 
 * @returns HTML element BtnBgPlum70Send component
 */
export default function BtnBgPlum70Send(props:{onClick:()=>void})  {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;
    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <a className="button-wo-border bg-divelit-plum-70 paragraph-small" onClick={props.onClick}>{translation.btn.send}<img className="button-arrow"></img></a>
        </>
    );
}

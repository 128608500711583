// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col, Table } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

//page sections
import EmbeddedForm from "./components/embedded-form/embedded-form";
import EmbeddedMoto from "./components/embedded-moto/embedded-moto";
import EmbeddedFields from "./components/embedded-fields/embedded-fields";

// files



/**
 * CybersecTraining section
 * informs readers about our competences and what we can train them on
 * @returns HTML code with CybersecTraining section for CYBERSEC page
 */
export default function CybersecEmbedded() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    
    return (
        <>  
            <div className="bg-white">
                <EmbeddedMoto></EmbeddedMoto>
                <EmbeddedForm></EmbeddedForm>
                <EmbeddedFields></EmbeddedFields>

            </div>
        </>
    );
}

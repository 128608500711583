// utils
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldHalved, faUserShield, faShield, faShieldAlt, faVirus, faUserSecret, faWifi, faHistory, faEnvelope, faKey, faLock, faDesktop, faGlobeAmericas, faUser, faUserGraduate, faExclamationTriangle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

// page sections
import cybersec_training from'../../../../../../files/images/pages/cybersec/cybersec_training.webp';



/**
 * TrainingMoto section
 * gives user few of the most importatnt motos/reasons why his employeees should be trained in cybersec
 * @returns HTML code with TrainingMoto section for training bookmark on CYBERSEC page
 */
export default function TrainingMoto() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    

    const words = document.getElementById('career-floating-header')
    var rect = words?.getBoundingClientRect();

    console.log(rect?.top, rect?.bottom);
    window.addEventListener('scroll', function() {
        let scrollTop = this.window.scrollY;
        console.log("SCROLL", this.screenTop);
        if (scrollTop > 0 && scrollTop < 2000 && this.window.innerWidth >= 991)
        {
            let topOffset = (scrollTop-500) /10
            this.document.getElementById('career-floating-header')?.style.setProperty('top', topOffset + 'px')
        }
    });


    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    
    return (
        <>  
            <Container className="pt-3 pt-md-5 pb-3 pb-md-5">
                <Container className="pt-3 pt-md-5 pb-3 pb-md-5">
                    <Row className="font-divelit-grey-50 fst-italic pb-3 pb-md-5 mb-3 mb-md-5 fade-in-750-down-to-top">  
                        <Col xs={2} sm={2} md={1} className="pe-0">
                            <table className="h-100 cybersec-training-moto-quotation-bottom">
                                <tbody>
                                    <tr>
                                        <td className="align-bottom" style={{fontSize: ""}}><span style={{fontSize: ""}}>„</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col xs={8} sm={8} md={10} className="text-center mx-auto">
                            <h4 className="fst-italic">
                            {translation.moto}
                            </h4>
                        </Col>
                        <Col xs={2} sm={2} md={1} className="ps-0">
                            <table className="h-100 cybersec-training-moto-quotation-top">
                                <tbody>
                                    <tr>
                                        <td className="align-baseline" style={{fontSize: ""}}><span style={{fontSize: ""}}>“</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    {/*<img src={img_5683} className="fade-in-750-down-to-top project-tile-border project-tile-box-shadow-divelit-grey-20" style={{height: "25rem"}} alt={"sa"}/>*/}
                    <Row>
                        <Col xs={12} lg={6}>
                            <Col xs={12} sm={12} className="fade-in-750-down-to-top">
                                <Row className="style-zoom-3">
                                    {/*<Col md={1} className="d-lg-none"></Col>*/}
                                    <Col xs={10} sm={9} md={9} lg={10} className="project-tile-border project-tile-box-shadow-divelit-grey-10 bg-divelit-plum-20 z-2" style={{padding: "36px"}}>
                                        <p className="paragraph-medium mb-0">
                                            {translation.paragraph.no_01}
                                        </p>
                                    </Col>
                                    <Col xs={2} sm={1} md={1} lg={2} className="z-3" >
                                        <FontAwesomeIcon icon={faExclamationTriangle} aria-hidden="true" className="font-divelit-plum h-auto mx-auto cybersec-training-moto-icon-size position-relative"/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} className="fade-in-1000-down-to-top">
                                <Row className="mt-5 style-zoom-3">
                                    <Col sm={1} md={1} className="d-lg-none"></Col>
                                    <Col xs={10} sm={9} md={9} lg={10} className="project-tile-border project-tile-box-shadow-divelit-grey-10 bg-divelit-plum-30 z-2" style={{padding: "36px"}}>
                                        <p className="paragraph-medium mb-0">
                                            {translation.paragraph.no_02}
                                        </p>
                                    </Col>
                                    <Col xs={2} sm={1} md={1} lg={2} className="z-3">
                                        <FontAwesomeIcon icon={faShieldAlt} aria-hidden="true" className="font-divelit-plum h-auto mx-auto cybersec-training-moto-icon-size position-relative"/>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={12} sm={12} className="fade-in-1250-down-to-top">
                                <Row className="mt-5 style-zoom-3">
                                    <Col sm={2} md={2} className="d-lg-none"></Col>
                                    <Col xs={10} sm={9} md={9} lg={10} className="project-tile-border project-tile-box-shadow-divelit-grey-10 bg-divelit-plum-40 z-2" style={{padding: "36px"}}>
                                        <p className="paragraph-medium mb-0">
                                            {translation.paragraph.no_03}
                                        </p>
                                    </Col>
                                    <Col xs={2} sm={1} md={1} lg={2} className="z-3">
                                        <FontAwesomeIcon icon={faUserGraduate} aria-hidden="true" className="font-divelit-plum h-auto mx-auto cybersec-training-moto-icon-size position-relative"/>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                        <Col xs={12} lg={6} className="mt-md-4 mt-lg-0 pt-md-4 pt-lg-0 fade-in-1500-down-to-top">
                            {/*<img src={img_5726} className="fade-in-750-down-to-top project-tile-border project-tile-box-shadow-divelit-grey-20" style={{width: "100%", height: "100%", objectFit: "cover"}} alt={"sa"}/>*/}
                            {/*<img src={img_5713} className="fade-in-750-down-to-top project-tile-border project-tile-box-shadow-divelit-grey-20" style={{width: "100%", height: "100%", objectFit: "cover", filter: "grayscale(0.5)"}} alt={"sa"}/>*/}
                            <img src={cybersec_training} id="career-floating-header" className="fade-in-750-down-to-top project-tile-border project-tile-box-shadow-divelit-grey-20 w-100 h-100 object-fit-cover position-relative" alt={translation.alt.moto_img}/>
                        </Col>
                    </Row>
                    <div style={{marginTop: "-5rem"}}></div>
                </Container>
            </Container>
        </>
    );
}

// utils
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments, faMap, faChartLine, faArrowUpWideShort, faFile, faLegal, faSitemap, faMicrochip, faUsers, faUserGraduate, faExclamationTriangle, faQuestionCircle, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { ArcherContainer, ArcherElement } from 'react-archer';

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_black from "../../../../../../files/icons/rency/square_black.svg";
import square_plum from "../../../../../../files/icons/rency/square_plum.svg";

const rootStyle = { display: 'flex', justifyContent: 'center' };
const rowStyle = { margin: '5rem 0', display: 'flex', justifyContent: 'space-between' };
const rowStyle2 = { margin: '0 0', display: 'flex', justifyContent: 'space-between'};
const boxStyle = { padding: '10px', border: '1px solid black' };

/**
 * ManagementDiagram section
 * gives user a brief overview about different phases of our cooperation
 * @returns HTML code with ManagementDiagram section for management bookmark on CYBERSEC page
 */
export default function ManagementDiagram() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    
    return (
        <>
            <Container className="pt-3 pt-md-5 pb-3 pb-md-5">
                <Container className="pt-3 pt-md-5 pb-3 pb-md-5">
                    
                    {/*<img src={img_5683} className="fade-in-750-down-to-top project-tile-border project-tile-box-shadow-divelit-grey-20" style={{height: "25rem"}} alt={"sa"}/>*/}
                    <Row>
                        <Col className="">
                            <div className="d-flex flex-row justify-content-center align-items-center scroll-anim-750">
                                <h4 className="w-fit-content">{translation.header}</h4>
                                {/*<FontAwesomeIcon icon={faQuestionCircle} aria-hidden="true" className="font-divelit-plum ms-3 w-fit-content" style={{height: "7rem"}}/>*/}
                            </div>
                        </Col>
                    </Row>

                    {/*<Col xs={12} sm={12} lg={4}>
                        <div className="p-0 p-xl-2 p-xxl-3">
                            <div className="project-tile-border bg-divelit-plum-70 project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1250 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                <FontAwesomeIcon icon={faComments} aria-hidden="true" className="font-divelit-plum h-50 w-50 mx-auto" style={{position: "relative", left: "0rem", top: "0rem"}}/>
                                <h5 className="mt-3 md-3 mt-md-5 font-white">Úvodní konzultace</h5>
                            </div>
                        </div>
                    </Col>*/}

                    <ArcherContainer className="d-none d-md-block" strokeColor="var(--divelit-plum)" style={{marginBottom: "-15rem"}}>
                        <div style={rowStyle}>
                            <Col xs={12} sm={12} md={4}>
                                <ArcherElement id="element1" relations={[{ targetId: 'element2', targetAnchor: 'left', sourceAnchor: 'right', style: { strokeColor: 'var(--divelit-plum)', strokeWidth: 8, strokeDasharray: "30,30", endMarker: false, lineStyle: "curve" },},]}>
                                        <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                            <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1000 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                                <FontAwesomeIcon icon={faComments} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem"}}/>
                                                {/*<div className="d-flex flex-row align-items-center mt-3 mt-md-4">
                                                    <img src={square_black} className="home-experts-img w-fit-content"></img>
                                                    <h4 className="mb-0 ms-3 w-fit-content">01</h4>
                                                </div>*/}
                                                <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_1}</h5>
                                                {/*<p className="paragraph-small text-start">
                                                    translation.cards.n o_02.paragraph translation.cards.n o_02.paragraph asda asd as hkjjkghfjgasddfu  jhlaasghv dkja jhvj,h
                                                </p>*/}
                                            </div>
                                        </div>
                                </ArcherElement>
                            </Col>
                            <Col xs={12} sm={12} md={1}></Col>
                            <Col xs={12} sm={12} md={4} className="mt-5">
                                <ArcherElement id="element2" relations={[{ targetId: 'element3', targetAnchor: 'top', sourceAnchor: 'bottom', style: { strokeColor: 'var(--divelit-plum)', strokeWidth: 8, strokeDasharray: "30,30", endMarker: false, lineStyle: "curve" },},]}>
                                    <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                        <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1500 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                            <FontAwesomeIcon icon={faMap} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem" }}/>
                                            <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_2}</h5>
                                        </div>
                                    </div>
                                </ArcherElement>
                            </Col>
                            <Col xs={12} sm={12} md={1}></Col>
                        </div>
                        <div style={rowStyle}>
                            <Col xs={12} sm={12} md={4} className="ms-md-5 mt-md-5">
                                <ArcherElement id="element3" relations={[{ targetId: 'element4', targetAnchor: 'left', sourceAnchor: 'right', style: { strokeColor: 'var(--divelit-plum)', strokeWidth: 8, strokeDasharray: "30,30", endMarker: false, lineStyle: "curve" },},]}>
                                    <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                        <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1000 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                            <FontAwesomeIcon icon={faChartLine} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem" }}/>
                                            <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_3}</h5>
                                        </div>
                                    </div>
                                </ArcherElement>
                            </Col>
                            <Col xs={12} sm={12} md={4} className="me-md-3">
                                <ArcherElement id="element4" relations={[{ targetId: 'element5', targetAnchor: 'top', sourceAnchor: 'bottom', style: { strokeColor: 'var(--divelit-plum)', strokeWidth: 8, strokeDasharray: "30,30", endMarker: false, lineStyle: "curve" },},]}>
                                    <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                        <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1500 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                            <FontAwesomeIcon icon={faArrowUpWideShort} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem" }}/>
                                            <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_4}</h5>
                                        </div>
                                    </div>
                                </ArcherElement>
                            </Col>
                        </div>
                        <div style={rowStyle}>
                            <Col xs={12} sm={12} md={1}></Col>
                            <Col xs={12} sm={12} md={4} className="mt-md-4">
                                <ArcherElement id="element6">
                                    <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                        <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1500 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                            <FontAwesomeIcon icon={faHandshake} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem" }}/>
                                            <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_5}</h5>
                                        </div>
                                    </div>
                                </ArcherElement>
                            </Col>
                            <Col xs={12} sm={12} md={2}></Col>
                            <Col xs={12} sm={12} md={4} className="">
                                <ArcherElement id="element5" relations={[{ targetId: 'element6', targetAnchor: 'right', sourceAnchor: 'left', style: { strokeColor: 'var(--divelit-plum)', strokeWidth: 8, strokeDasharray: "30,30", endMarker: false, lineStyle: "curve" },},]}>
                                    <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                        <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1000 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                            <FontAwesomeIcon icon={faFile} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem" }}/>
                                            <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_6}</h5>
                                        </div>
                                    </div>
                                </ArcherElement>
                            </Col>
                            <Col xs={12} sm={12} md={1}></Col>
                        </div>
                    </ArcherContainer>







                    <ArcherContainer className="d-md-none" strokeColor="var(--divelit-plum)" style={{marginBottom: "-10rem"}}>
                        <div style={rowStyle2}>
                            <Col xs={9} sm={9} md={4} className="mt-5">
                                <ArcherElement id="element1" relations={[{ targetId: 'element2', targetAnchor: 'right', sourceAnchor: 'right', style: { strokeColor: 'var(--divelit-plum)', strokeWidth: 8, strokeDasharray: "30,30", endMarker: false, lineStyle: "curve" },},]}>
                                        <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                            <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1000 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                                <FontAwesomeIcon icon={faComments} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem"}}/>
                                                {/*<div className="d-flex flex-row align-items-center mt-3 mt-md-4">
                                                    <img src={square_black} className="home-experts-img w-fit-content"></img>
                                                    <h4 className="mb-0 ms-3 w-fit-content">01</h4>
                                                </div>*/}
                                                <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_1}</h5>
                                                {/*<p className="paragraph-small text-start">
                                                    translation.cards.n o_02.paragraph translation.cards.n o_02.paragraph asda asd as hkjjkghfjgasddfu  jhlaasghv dkja jhvj,h
                                                </p>*/}
                                            </div>
                                        </div>
                                </ArcherElement>
                            </Col>
                        </div>
                        <div style={rowStyle2}>
                            <Col xs={3} sm={3}></Col>
                            <Col xs={9} sm={9} className="mt-5">
                                <ArcherElement id="element2" relations={[{ targetId: 'element3', targetAnchor: 'left', sourceAnchor: 'left', style: { strokeColor: 'var(--divelit-plum)', strokeWidth: 8, strokeDasharray: "30,30", endMarker: false, lineStyle: "curve" },},]}>
                                    <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                        <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1500 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                            <FontAwesomeIcon icon={faMap} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem" }}/>
                                            <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_2}</h5>
                                        </div>
                                    </div>
                                </ArcherElement>
                            </Col>
                            
                        </div>
                        <div style={rowStyle2} className="mt-4">
                            <Col xs={9} sm={9} className="mt-5">
                                <ArcherElement id="element3" relations={[{ targetId: 'element4', targetAnchor: 'left', sourceAnchor: 'left', style: { strokeColor: 'var(--divelit-plum)', strokeWidth: 8, strokeDasharray: "30,30", endMarker: false, lineStyle: "curve" },},]}>
                                    <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                        <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1000 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                            <FontAwesomeIcon icon={faChartLine} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem" }}/>
                                            <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_3}</h5>
                                        </div>
                                    </div>
                                </ArcherElement>
                            </Col>
                            <Col xs={3} sm={3}></Col>
                        </div>
                        <div style={rowStyle2}>
                            <Col xs={3} sm={3}></Col>
                            <Col xs={9} sm={9} className="mt-5">
                                <ArcherElement id="element4" relations={[{ targetId: 'element5', targetAnchor: 'right', sourceAnchor: 'right', style: { strokeColor: 'var(--divelit-plum)', strokeWidth: 8, strokeDasharray: "30,30", endMarker: false, lineStyle: "curve" },},]}>
                                    <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                        <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1500 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                            <FontAwesomeIcon icon={faArrowUpWideShort} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem" }}/>
                                            <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_4}</h5>
                                        </div>
                                    </div>
                                </ArcherElement>
                            </Col>
                        </div>
                        <div style={rowStyle2}>
                            <Col xs={9} sm={9} className="mt-5">
                                <ArcherElement id="element5" relations={[{ targetId: 'element6', targetAnchor: 'top', sourceAnchor: 'right', style: { strokeColor: 'var(--divelit-plum)', strokeWidth: 8, strokeDasharray: "30,30", endMarker: false, lineStyle: "curve" },},]}>
                                    <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                        <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1000 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                            <FontAwesomeIcon icon={faFile} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem" }}/>
                                            <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_6}</h5>
                                        </div>
                                    </div>
                                </ArcherElement>
                            </Col>
                            <Col xs={3} sm={3}></Col>
                        </div>
                        <div style={rowStyle2} className="mt-5">
                            <Col xs={3} sm={3}></Col>
                            <Col xs={9} sm={9} className="mt-5">
                                <ArcherElement id="element6">
                                    <div className="p-0 p-xl-2 p-xxl-3 style-zoom-3">
                                        <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1500 text-center" style={{borderTop: "12px solid var(--divelit-plum-70)"}}>
                                            <FontAwesomeIcon icon={faHandshake} aria-hidden="true" className="font-divelit-plum-70 mx-auto" style={{position: "relative", left: "0rem", top: "0rem", width: "auto", height: "5rem" }}/>
                                            <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_5}</h5>
                                        </div>
                                    </div>
                                </ArcherElement>
                            </Col>
                        </div>
                    </ArcherContainer>
                    <Row className="mt-3 mt-lg-5">














                    {/*<Col xs={12} sm={12} lg={4}>
                        <div className="p-0 p-xl-2 p-xxl-3">
                            <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1500 career-recruitment-card-height"  style={{borderTop: "12px solid var(--divelit-plum-50)", position: "relative", top: "2rem"}}>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={square_black} className="home-experts-img w-fit-content"></img>
                                    <h4 className="mb-0 ms-3 w-fit-content">02</h4>
                                </div>
                                <h5 className="mt-3 md-3 mt-md-5">translation.cards.no_02.header</h5>
                                <p className="paragraph-small">
                                    translation.cards.no_02.paragraph
                                </p>                            
                            </div>                            
                        </div>
                    </Col>
                    <Col xs={12} sm={12} lg={4}>
                        <div className="p-0 p-xl-2 p-xxl-3">
                            <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1750 career-recruitment-card-height"  style={{borderTop: "12px solid var(--divelit-plum-90)", position: "relative", top: "4rem"}}>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={square_black} className="home-experts-img w-fit-content"></img>
                                    <h4 className="mb-0 ms-3 w-fit-content">03</h4>
                                </div>
                                <h5 className="mt-3 md-3 mt-md-5">translation.cards.no_03.header</h5>
                                <p className="paragraph-small">
                                    translation.cards.no_03.paragraph
                                </p>
                            </div>                            
                        </div>
                    </Col>*/}
                </Row>
                </Container>
            </Container>
        </>
    );
}

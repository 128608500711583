// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldHalved, faUserShield, faVirus, faUserSecret, faWifi, faHistory } from '@fortawesome/free-solid-svg-icons';

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_lemon from "../../../../files/icons/rency/square_lemon.svg";
import square_black from "../../../../files/icons/rency/square_black.svg";
import square_plum from "../../../../files/icons/rency/square_plum.svg";
import hw_competence from "../../../../files/images/pages/embedded/hw_competence.webp";
import cybersec_img from "../../../../files/images/pages/cybersec/cybersec.jpg";


// page sections
import ManagementMoto from "./components/management-moto/management-moto";
import ManagementForm from "./components/management-form/management-form";
import ManagementDiagram from "./components/management-diagram/management-diagram";
import ManagementCollaboration from "./components/management-collaboration/management-collaboration";



/**
 * CybersecManagement section
 * informs readers about our competences and knowledge base in corporate security management
 * @returns HTML code with CybersecManagement section for CYBERSEC page
 */
export default function CybersecManagement() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>  

            <ManagementMoto></ManagementMoto>
            <ManagementDiagram></ManagementDiagram>
            <ManagementForm></ManagementForm>
            <ManagementCollaboration></ManagementCollaboration>
            {/*<div className="bg-white font-black color p-1 pt-5">
                <Container className="">
                    <Row className="scroll-anim-1250 mt-3 mt-lg-5 blog-older-container-me project-tile-box-shadow-divelit-grey-10 project-tile-border">
                        <Col xs={12} sm={12} lg={6} xl={7} className="order-2 order-lg-1 ps-0 pe-0 blog-older-container-ms-text-border bg-white" style={{borderTop: "12px solid var(--divelit-plum)"}}>
                            <div className="mt-3 me-3 ms-3 mb-2 mt-lg-5 me-lg-5 ms-lg-5 mb-lg-5">
                                <Row className="d-flex flex-row align-items-center">
                                    <img src={square_black} className="home-experts-img w-fit-content mb-2" alt={translation.alt.square_lemon}/>
                                    <h4 className="w-fit-content">{translation.hw_competence.header}</h4>
                                </Row>
                                <Row className="m-0">
                                    <Col size={12} className="mt-4 mt-lg-4 ms-2 ms-md-3 ms-xl-5 pe-0 ps-0" >
                                        <ul className="paragraph-small w-fit-content">
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part1}</li>
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part2}</li>
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part3}</li>
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part4}</li>
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part5}</li>
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part6}</li>
                                        </ul>  
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} lg={6} xl={5} className="order-1 order-lg-2 pe-0 ps-0 bg-white text-center blog-older-container-ms-img-border">
                            <div className="embedded-img-thumbnail blog-older-container-ms-img-border">
                                <div className="d-flex flex-column" >
                                    <p className="paragraph-medium align-self-center mt-auto mb-auto bg-divelit-lemon"></p>
                                </div>
                                <img className="blog-latest-img blog-older-container-ms-img-border pe-0" alt={translation.alt.hw_competence} src={cybersec_img}/>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>
            <div className="bg-white font-black" style={{marginBottom: "5rem"}}>
                <Container className="">
                    <Row>
                        <Col xs={12} sm={12} md={1}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="mt-3">
                            <Row className="mt-3 paragraph-medium ">
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faShieldHalved} className="font-divelit-plum w-100 h-100"/>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                        {translation.hw_competence.list.part1}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Nastavení a správa firewallů pro zajištění maximální ochrany sítě.</li>
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Detekce a blokování podezřelé aktivity</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={2}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="mt-1">
                            <Row className="mt-3 paragraph-medium ">
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                        {translation.hw_competence.list.part2}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Instalace, konfigurace a pravidelná aktualizace antivirových řešení.</li>
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Provádění pravidelných kontrol a skenování systému.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faVirus} className="font-divelit-plum w-100 h-100"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={1}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="mt-1">
                            <Row className="mt-3 paragraph-medium ">
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faUserSecret} className="font-divelit-plum w-100 h-100"/>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                        {translation.hw_competence.list.part3}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Nastavení a správa VPN pro bezpečné vzdálené připojení</li>
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Šifrování citlivých dat během přenosu a jejich uložení.</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={2}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="mt-1">
                            <Row className="mt-3 paragraph-medium ">
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                        {translation.hw_competence.list.part4}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Instalace, konfigurace a pravidelná aktualizace antivirových řešení.</li>
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Provádění pravidelných kontrol a skenování systému.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faUserShield} className="font-divelit-plum w-100 h-100"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={1}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="mt-1">
                            <Row className="mt-3 paragraph-medium ">
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faHistory} className="font-divelit-plum w-100 h-100"/>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                        {translation.hw_competence.list.part5}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Analýza systémových událostí.</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12} md={2}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="mt-1">
                            <Row className="mt-3 paragraph-medium ">
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                    {translation.hw_competence.list.part6}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Konfigurace a zabezpečení bezdrátových sítí.</li>
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Ochrana proti neautorizovanému přístupu.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faWifi} className="font-divelit-plum w-100 h-100"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>*/}







            {/*<div className="bg-white font-black pt-5">
                <Container className="">
                    <Row className="scroll-anim-1250 mt-3 mt-lg-5 blog-older-container-me bg-divelit-plum-60">
                        <Col xs={12} sm={12} className="project-tile-box-shadow-divelit-grey-10 project-tile-border">
                            <Row>
                                <Col xs={12} sm={12} lg={6} xl={7} className="order-2 order-lg-1 ps-0 pe-0">
                                    <div className="mt-3 me-3 ms-3 mb-2 mt-lg-5 me-lg-5 ms-lg-5 mb-lg-5">
                                        <Row className="d-flex flex-row align-items-center">
                                            <img src={square_black} className="home-experts-img w-fit-content mb-2" alt={translation.alt.square_lemon}/>
                                            <h4 className="w-fit-content">{translation.hw_competence.header}</h4>
                                        </Row>
                                        <Row className="m-0">
                                            <Col size={12} className="mt-4 mt-lg-4 ms-2 ms-md-3 ms-xl-5 pe-0 ps-0" >
                                                <ul className="paragraph-small w-fit-content">
                                                    <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part1}</li>
                                                    <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part2}</li>
                                                    <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part3}</li>
                                                    <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part4}</li>
                                                    <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part5}</li>
                                                    <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.hw_competence.list.part6}</li>
                                                </ul>  
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} lg={6} xl={5} className="order-1 order-lg-2 pe-0 ps-0 bg-white text-center">
                                    <div className="embedded-img-thumbnail blog-older-container-ms-img-border">
                                        <div className="d-flex flex-column" >
                                            <p className="paragraph-medium align-self-center mt-auto mb-auto bg-divelit-lemon"></p>
                                        </div>
                                        <img className="blog-latest-img blog-older-container-ms-img-border pe-0" alt={translation.alt.hw_competence} src={cybersec_img}/>
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                    <div style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>

                    <Row>
                        <Col xs={12} sm={12} md={1}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="">
                            <Row className="paragraph-medium ">
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faShieldHalved} className="font-divelit-plum w-100 h-100"/>

                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                        {translation.hw_competence.list.part1}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Nastavení a správa firewallů pro zajištění maximální ochrany sítě.</li>
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Detekce a blokování podezřelé aktivity</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>

                    <Row>
                        <Col xs={12} sm={12} md={2}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="">
                            <Row className="paragraph-medium ">
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                        {translation.hw_competence.list.part2}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Instalace, konfigurace a pravidelná aktualizace antivirových řešení.</li>
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Provádění pravidelných kontrol a skenování systému.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faVirus} className="font-divelit-plum w-100 h-100"/>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <div style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>

                    <Row>
                        <Col xs={12} sm={12} md={1}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="">
                            <Row className="paragraph-medium ">
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faUserSecret} className="font-divelit-plum w-100 h-100"/>


                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                        {translation.hw_competence.list.part3}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Nastavení a správa VPN pro bezpečné vzdálené připojení</li>
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Šifrování citlivých dat během přenosu a jejich uložení.</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>

                    <Row>
                        <Col xs={12} sm={12} md={2}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="">
                            <Row className="paragraph-medium ">
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                        {translation.hw_competence.list.part4}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Instalace, konfigurace a pravidelná aktualizace antivirových řešení.</li>
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Provádění pravidelných kontrol a skenování systému.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faUserShield} className="font-divelit-plum w-100 h-100"/>

                                </Col>
                            </Row>

                        </Col>
                    </Row>
                    <div style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>

                    <Row>
                        <Col xs={12} sm={12} md={1}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="">
                            <Row className="paragraph-medium ">
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faHistory} className="font-divelit-plum w-100 h-100"/>


                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                        {translation.hw_competence.list.part5}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Analýza systémových událostí.</li>
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>

                    <Row>
                        <Col xs={12} sm={12} md={2}>
                        </Col>
                        <Col xs={12} sm={12} md={10} className="">
                            <Row className="paragraph-medium ">
                                <Col xs={12} sm={12} md={8} className="p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <div>
                                    {translation.hw_competence.list.part6}
                                        <ul className="paragraph-small mt-4">
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Konfigurace a zabezpečení bezdrátových sítí.</li>
                                            <li className="ps-md-1 pt-1 pt-lg-1 pb-1 pb-lg-1 mb-0">Ochrana proti neautorizovanému přístupu.</li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={2} className="p-5 paragraph-medium bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <FontAwesomeIcon icon={faWifi} className="font-divelit-plum w-100 h-100"/>

                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>

                <Container className="">
                </Container>
            </div>*/}
        </>

    );
}

// utils
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLegal, faSitemap, faMicrochip, faUsers, faUserGraduate, faExclamationTriangle, faQuestionCircle, faHandshake, faChartLine, faMagnifyingGlassChart, faUserGear } from '@fortawesome/free-solid-svg-icons';

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files



/**
 * ManagementMoto section
 * gives user few of the most importatnt motos/reasons why he should care about corporate security managemnet
 * @returns HTML code with ManagementMoto section for management bookmark on CYBERSEC page
 */
export default function ManagementMoto() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    
    return (
        <>  
            <Container className="pt-3 pt-md-5 pb-3 pb-md-5">
                <Container className="pt-3 pt-md-5 pb-3 pb-md-5">
                    <Row className="font-divelit-grey-50 fst-italic pb-3 pb-md-5 mb-3 mb-md-5 fade-in-750-down-to-top">  
                        <Col xs={2} sm={2} md={1} className="pe-0">
                            <table className="h-100 cybersec-training-moto-quotation-bottom">
                                <tbody>
                                    <tr>
                                        <td className="align-bottom" style={{fontSize: ""}}><span style={{fontSize: ""}}>„</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                        <Col xs={8} sm={8} md={10} className="text-center mx-auto">
                            <h4 className="fst-italic">
                            {translation.moto}
                            </h4>
                        </Col>
                        <Col xs={2} sm={2} md={1} className="ps-0">
                            <table className="h-100 cybersec-training-moto-quotation-top">
                                <tbody>
                                    <tr>
                                        <td className="align-baseline" style={{fontSize: ""}}><span style={{fontSize: ""}}>“</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    {/*<img src={img_5683} className="fade-in-750-down-to-top project-tile-border project-tile-box-shadow-divelit-grey-20" style={{height: "25rem"}} alt={"sa"}/>*/}
                    {/*<Row>
                        <Col className="">
                            <div className="d-flex flex-row justify-content-center align-items-center scroll-anim-750">
                                <h4 className="w-fit-content">{translation.header}</h4>
                                <FontAwesomeIcon icon={faHandshake} aria-hidden="true" className="font-divelit-plum ms-3 w-fit-content" style={{height: "7rem"}}/>
                            </div>
                        </Col>
                    </Row>*/}
                    <Row className="scroll-anim-1000 mt-3 mt-lg-3 pt-3 pt-lg-3">
                        <Col xs={12} sm={12} lg={1}></Col>
                        <Col xs={12} sm={12} lg={10} className="">
                            <Row className="">
                                <Col xs={12} sm={12} md={3} xl={3} className="mb-3 mb-md-0 p-3 p-md-3 bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border d-flex flex-column justify-content-center w-md-100" >
                                    {/*<img className="h-100 w-100" alt={translation.alt.hw_competence} src={cybersec_img}/>*/}
                                    <FontAwesomeIcon icon={faHandshake} aria-hidden="true" className="font-divelit-plum h-50 w-50 mx-auto"/>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={8} className="p-3 p-md-5 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <h5>{translation.cards.no_1.header}</h5>
                                    <p className="paragraph-small mt-3">{translation.cards.no_1.paragraph}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} lg={1}></Col>
                    </Row>
                    <Row className="scroll-anim-1000 mt-4">
                        <Col xs={12} sm={12} lg={2}></Col>
                        <Col xs={12} sm={12} lg={10} className="">
                            <Row className="">
                                <Col xs={12} sm={12} md={8} className="p-3 p-md-5 order-3 order-md-1 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                <h5>{translation.cards.no_2.header}</h5>
                                <p className="paragraph-small mt-3">{translation.cards.no_2.paragraph}</p>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}} className="order-1 order-md-2"></Col>
                                <Col xs={12} sm={12} md={3} xl={3} className="mt-3 mt-md-0 mb-3 mb-md-0 p-3 p-md-3 order-2 order-md-3 bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border d-flex flex-column justify-content-center w-md-100 ms-auto me-0 ms-md-0" >
                                    {/*<img className="h-100 w-100" alt={translation.alt.hw_competence} src={cybersec_img}/>*/}
                                    <FontAwesomeIcon icon={faChartLine} aria-hidden="true" className="font-divelit-plum h-50 w-50 mx-auto"/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="scroll-anim-1000 mt-4">
                        <Col xs={12} sm={12} lg={1}></Col>
                        <Col xs={12} sm={12} lg={10} className="">
                            <Row className="">
                                <Col xs={12} sm={12} md={3} xl={3} className="mb-3 mb-md-0 p-3 p-md-3 bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border d-flex flex-column justify-content-center w-md-100" >
                                    {/*<img className="h-100 w-100" alt={translation.alt.hw_competence} src={cybersec_img}/>*/}
                                    <FontAwesomeIcon icon={faUserGear} aria-hidden="true" className="font-divelit-plum h-50 w-50 mx-auto"/>
                                </Col>
                                <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                                <Col xs={12} sm={12} md={8} className="p-3 p-md-5 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                    <h5>{translation.cards.no_3.header}</h5>
                                    <p className="paragraph-small mt-3">{translation.cards.no_3.paragraph}</p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} lg={1}></Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

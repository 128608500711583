// utils
import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";
import { isValidEmailAddress } from "../../../../utils/validationUtils"
import FormData from "form-data";
import { Work_position } from "../../types/work_positions";
import { select } from "../career-work-positions/components/work-position/components/work-position-select/work-position-select";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import loading_animation from "../../../../files/animations/pulsing-stripes-divelit-colors.svg";
import BtnBgLemonSend from "../../../../components/btn/btn-bg-lemon-send";

// storage for data obtained from backend
let workPositions:any = null;
let workPositionsList:any = [];
let requestErrorCode = "";
let requestErrorText = "";

/**
 * CareerForm section 
 * Handles <form> element for the CAREER page - language, required input check, POST to the server as multipart/form-data
 * @param props 1) work position
 * @returns HTMl code for CareerForm section for CAREER apge
 */
//export default function CareerForm(props: {work_positions: [Work_position]|[]}) {
export default function CareerForm() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;
    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    const [isLoadingPositions, setIsLoadingPositions] = useState(false);

    const [isLoaded, setIsLoaded] = useState(false);
    const [requestError, setRequestError] = useState(false);

    // On PAGE load DATA from backend are REQUESTED
    useEffect(() => {
        // Sets GLOBAL LOADING to TRUE
        //globalLoading.switch();
        setIsLoadingPositions(true);

        workPositionsList = [];
        axios.get("/api/career/getWorkPositionsAll", { params: { lang: language }, timeout: 5000 })
            .then(function (response) {

                workPositions = response.data.data.work_positions;
                
                //console.log(workPositions);
                
                workPositions.map((item:any, i:any) => (
                    workPositionsList.push(item.name)
                    //console.log(item.name)
                ))
                //console.log(workPositionsList);
                // Sets GLOBAL LOADING to FALSE
                //globalLoading.switch();
                setIsLoadingPositions(false);

                setIsLoaded(true);

                // DEBUG simulates waiting for data - used for debugging
                //window.setTimeout(() => {globalLoading.switch();}, 5000);
            })
            .catch(function (error) {
                // Sets GLOBAL LOADING to FALSE
                //globalLoading.switch();
                setIsLoadingPositions(false);
                
                // DEBUG whole error message
                //console.log(error);
                
                // error response from backend
                if(error?.code === 'ECONNABORTED') {
                    requestErrorCode = "408";
                    requestErrorText = "Request timeout";
                }
                else {
                    requestErrorCode = "500";
                    requestErrorText = "Internal server error";
                }

                //if(error?.response?.status) {}

                setRequestError(true);
                //console.log(requestError);
            });
    }, [])

    // Default form fields values
    const [selectPosition, setSelectPosition] = useState("");
    const [inputName, setInputName] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [inputLinkedIn, setInputLinkedIn] = useState("");
    const [inputGithub, setInputGithub] = useState("");
    const [inputCV, setInputCV] = useState<File>();
    const [checkBoxConsent, setCheckBoxConsent] = useState(false);
    const [formMsg, setFormMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorMsg, setIsErrorMsg] = useState(false);
    
    // Upload file button element - used for changing button inner text
    const fileButton = document.getElementById("file_button") as HTMLLabelElement;

    // TODO possible future inspiration otherwise DELETE
    //const [selectedFiles, setSelectedFiles] = useState<File | undefined>(
    //    undefined
    //);


    /**
     * Handles file submit 
     * - sets button inner text to the name of the uploaded file
     * - uplaoded file is added as an item into the 'data'
     * @returns 'data' with added .pdf file
     */
    const handlePdfFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        if(event?.target?.files?.[0]) {
            fileButton.innerHTML = String(event?.target?.files?.[0].name)
            setInputCV(event?.target?.files[0]);
            
            //console.log(event?.target?.value);                
            //console.log(event?.target?.files?.[0].size);                
        }
    }

    /**
     * On form submit is being checked whether user filled all the required fields or not and if given email is valid
     * @returns if POST is successfull GREEN message is displayed and all fields are deleted
     * @returns if POST was not successfull RED message is displayed and input remains untouched 
     */
    const handleSubmitForm = () => {

        // all <form> data to POST will be stored in this variable in multipart/form-data format 
        let data = new FormData();

        //console.log(selectPosition,inputName,inputEmail,inputLinkedIn,inputGithub,inputCV,checkBoxConsent)
        if(selectPosition === "")
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.select_position_error);
            return;
        }
        if(inputName === "")
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.career_name_error);
            return;
        }
        if(inputEmail === "")
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.career_email_missing_error);
            return;
        }
        if(inputCV === undefined)
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.career_cv_missing_error);
            return;
        }
        if(inputCV.size > 2097152) {
            setIsErrorMsg(true);
            setFormMsg(translation.form.career_cv_too_large);
            return;
        }
        else if(!isValidEmailAddress(inputEmail))
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.career_email_invalid_error);
            return;
        }
        if(!checkBoxConsent)
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.career_consent_error);
            return;
        }

        setFormMsg("");
        setIsLoading(true);
        
        // adding all other inputs into 'data'
        data.append("career_position", selectPosition);
        data.append("career_name", inputName);
        data.append("career_email", inputEmail);
        data.append("career_linkedin", inputLinkedIn);
        data.append("career_github", inputGithub);
        data.append("career_cv_file", inputCV);

        console.log(data);
        // if SUCCESS delete all inputs and use UPLOAD button default text (otherwise let be)
        axios.post("/api/career/form", data, {
            headers: {
                "Content-type": "multipart/form-data"
            },
            params: {
                lang: language
            }
          })
        .then(function (response) {
            setIsLoading(false);
            setIsErrorMsg(false);
            setFormMsg(translation.form.form_send_success);
            console.log(response);

            setSelectPosition("");
            setInputName("");
            setInputEmail("");
            setInputLinkedIn("");
            setInputGithub("");
            setInputCV(undefined);
            fileButton.innerHTML = String(translation.form.career_cv_file);
            setCheckBoxConsent(false);
        })
        .catch(function (error) {
            setIsLoading(false);
            setIsErrorMsg(true);
            setFormMsg(translation.form.form_send_error);
            console.log(error);
        });
    }


    /**
     * This piece is responsible for updating value of SELECT from FORM 
     * in case when user clicks on BTN "I WANT TO KNOW MORE" when viewing WORK POSITION
     */
    let tmp = select.value;
    let buttons = document.getElementsByClassName("btn-know-more-listener");

    for(let i = 0; i < buttons.length; i++)
    {
        buttons[i].addEventListener('click', ()=>{
            // few ms timeout is required so the value is changed after click 
            setTimeout(() => {
                if(tmp !== select.value){
                    setSelectPosition(select.value);
                    tmp = select.value;
                }
                tmp = select.value;
                //checkLoading();
            }, 10);    

        }, true);
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    return (
        <div className="bg-divelit-grey-05 pt-3 pt-lg-5 pb-3 pb-lg-5">
            <span id="form_section"></span>
            <Container className=" mt-3 mt-lg-5 mb-3 mb-lg-5">
                <Row className="">
                    <Col xs={12} sm={12} md={6} className="">
                        <h4 className="scroll-anim-750">{translation.benefits.header.part1}</h4>
                        <h4 className="mb-3 mb-md-5 scroll-anim-750">{translation.benefits.header.part2}</h4>

                        <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-md-5 mb-0 scroll-anim-1000"></hr>
                        <div className="scroll-anim-1000">
                            <p className="paragraph-small style-zoom-3 ps-md-4 pt-4 pb-4 mb-0 ">{translation.benefits.part1}</p>
                        </div>
                        <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                        <div className="scroll-anim-1000">
                            <p className="paragraph-small style-zoom-3 ps-md-4 pt-4 pb-4 mb-0">{translation.benefits.part2}</p>
                        </div>
                        <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                        <div className="scroll-anim-1000">
                            <p className="paragraph-small style-zoom-3 ps-md-4 pt-4 pb-4 mb-0">{translation.benefits.part3}</p>
                        </div>
                        <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                        <div className="scroll-anim-1000">
                            <p className="paragraph-small style-zoom-3 ps-md-4 pt-4 pb-4 mb-0">{translation.benefits.part4}</p>
                        </div>
                        <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                        <div className="scroll-anim-1000">
                            <p className="paragraph-small style-zoom-3 ps-md-4 pt-4 pb-4 mb-0">{translation.benefits.part5}</p>
                        </div>
                        <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                        <div className="scroll-anim-1000">
                            <p className="paragraph-small style-zoom-3 ps-md-4 pt-4 pb-4 mb-0">{translation.benefits.part6}</p>
                        </div>
                        <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                        <div className="scroll-anim-1000">
                            <p className="paragraph-small style-zoom-3 ps-md-4 pt-4 pb-4 mb-0">{translation.benefits.part7}</p>
                        </div>
                        <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                    </Col>
                    <Col xs={12} sm={12} md={6} className="">
                        <h4 id="career_send_pos" className="mt-5 mt-md-0 scroll-anim-750">{translation.let_us_know.part1}</h4>
                        <h4 className="scroll-anim-750">{translation.let_us_know.part2}</h4>
                        <form id="career_form" className="paragraph-small mt-4 mt-md-5" encType="multipart/form-data">
                            <Row className="scroll-anim-1000">
                                <Col xs={12} sm={12} lg={12} className="">
                                    <select className="form-input-style" 
                                            id="career_position" 
                                            name="career_position" 
                                            value={selectPosition}                                                                     
                                            onChange={(e) => setSelectPosition(e.target.value)}>
                                        <option className="ff-form-font" value="" disabled={true} style={{display: "none"}}>{translation.form.select_position}</option>
                                        {
                                            workPositionsList.map((item:any, i:any) => (
                                                <option className="ff-form-font" key={i} value={item}>{item}</option>
                                            ))
                                        }
                                    </select>
                                </Col>
                            </Row>
                            <Row className="scroll-anim-1000">
                                <Col xs={12} sm={12} lg={6} className="pt-4">
                                    <input className="form-input-style" id="career_name" type="text"
                                           name="career_name" placeholder={translation.form.career_name}
                                            value={inputName}
                                            onChange={(e) => setInputName(e.target.value)}/>
                                            
                                </Col>
                                <Col xs={12} sm={12} lg={6} className="pt-4">
                                    <input className="form-input-style" id="career_email" type="text"
                                           name="career_email" placeholder={translation.form.career_email}
                                           value={inputEmail}
                                           onChange={(e) => setInputEmail(e.target.value)}/>
                                </Col>
                            </Row>
                            <Row className="scroll-anim-1000">
                                <Col xs={12} sm={12} className="pt-4">
                                    <input className="form-input-style" id="career_linkedin" type="text"
                                           name="career_linkedin" placeholder={translation.form.career_linkedin}
                                           value={inputLinkedIn}
                                           onChange={(e) => setInputLinkedIn(e.target.value)}/>
                                </Col>
                            </Row>
                            <Row className="scroll-anim-1000">
                                <Col xs={12} sm={12} className="pt-4">
                                    <input className="form-input-style" id="career_github" type="text"
                                           name="career_github" placeholder={translation.form.career_github}
                                           value={inputGithub}
                                           onChange={(e) => setInputGithub(e.target.value)}/>
                                </Col>
                            </Row>
                            <Row className="scroll-anim-1000">
                                <Col xs={12} sm={12} className=" pt-4">    
                                    <a className="button-upload paragraph-medium mt-2 text-center">
                                        <label className="" style={{cursor: "pointer", width: "100%", height: "100%"}} htmlFor="cv_file" id="file_button">
                                            {translation.form.career_cv_file}
                                        </label>
                                        <input id="cv_file" style={{visibility:"hidden", width: 0, height: 0}} type={"file"} name="career_cv_file" accept="application/pdf" onChange={handlePdfFileChange} tabIndex={-1}></input>
                                    </a>
                                </Col>
                            </Row>
                            <Row className="scroll-anim-1000">
                                <Col xs={12} sm={12} className="pt-4">
                                    {/*<input id="career_checkbox" type="checkbox" name="consent"
                                           checked={checkBoxConsent}
                                           onChange={(e) => setCheckBoxConsent(e.target.checked)}/>&ensp;
                                    <label htmlFor='consent'>{translation.form.consent.part1}
                                        <a className="link-href" href={process.env.PUBLIC_URL + '/pdf/GDPR.pdf'} target="_blank"  rel="noreferrer">{translation.form.consent.part2}</a>{translation.form.consent.part3}&nbsp;*
                                    </label>*/}
                                    <div className="d-flex flex-row">
                                        <label className="checkbox-container-lemon">
                                            <input type="checkbox" name="consent"
                                            checked={checkBoxConsent}
                                            onChange={(e) => setCheckBoxConsent(e.target.checked)}/>
                                            <span className="checkmark-lemon"></span>
                                        </label>
                                        <div className="" style={{lineHeight: "2rem"}}>
                                            {translation.form.consent.part1}<a className="link-href" href={process.env.PUBLIC_URL + '/pdf/' + translation.form.gdpr} target="_blank"  rel="noreferrer">{translation.form.consent.part2}</a><span className="paragraph-small">{translation.form.consent.part3}</span>
                                        </div>                                                    
                                    </div> 
                                    <p className="mt-4 paragraph-extra-small">{translation.form.required}</p>
                                </Col>
                            </Row>
                            <Row className="scroll-anim-1000">
                                <Col xs={"auto"} sm={"auto"} className="">
                                    {/*<a className="button paragraph-medium mt-2" onClick={handleSubmitForm}>{translation.form.send_btn}<img className="button-arrow"></img></a>*/}
                                    <BtnBgLemonSend onClick={handleSubmitForm}></BtnBgLemonSend>
                                </Col>
                                <Col xs={"auto"} sm={"auto"} className="">
                                    <div className="form-message-box mt-2 mt-lg-0">
                                        {isLoading ?
                                            <img src={loading_animation}
                                                 className="form-loading-animation"/>
                                            :
                                            <span style={{color: isErrorMsg ? "red" : "green"}}>{formMsg}</span>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

// utils
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faCrosshairs } from '@fortawesome/free-solid-svg-icons';

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_black from  "../../../../../../files/icons/rency/square_black.svg";
import square_plum from  "../../../../../../files/icons/rency/square_plum.svg";



/**
 * EmbeddedFields section
 * gives user few of the most importatnt motos/reasons why embedded cybsecurity matter
 * @returns HTML code with EmbeddedFields section for embedded bookmark on CYBERSEC page
 */
export default function EmbeddedFields() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    
    return (
        <>  
            <Container className="pt-3 pt-md-5 pb-3 pb-md-5">
                <Container className="pt-3 pt-md-5 pb-3 pb-md-5">

                    {/*<img src={img_5683} className="fade-in-750-down-to-top project-tile-border project-tile-box-shadow-divelit-grey-20" style={{height: "25rem"}} alt={"sa"}/>*/}
                    <Row>
                        <Col className="">
                            <div className="d-flex flex-row justify-content-center align-items-center scroll-anim-750">
                                <h4 className="w-fit-content mb-0">{translation.header}</h4>
                                <FontAwesomeIcon icon={faCrosshairs} aria-hidden="true" className="font-divelit-plum ms-3 ms-md-4 w-fit-content" style={{height: "6rem"}}/>
                            </div>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col xs={12} sm={12}>
                            <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-5 mb-0 scroll-anim-1000"></hr>
                        </Col>
                    </Row>*/}
                    <Row>
                        <Col md={2}></Col>
                        <Col xs={12} sm={12} md={8} className="scroll-anim-1000 mt-3 mt-md-5">
                            <div className=" style-zoom-3">
                                <div className="d-flex flex-row align-items-center mt-4 mb-4">
                                    <img src={square_plum} className="home-experts-img w-fit-content" alt={translation.alt.square_plum}></img>
                                    <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum">01</h4>
                                    {/*<h5 className="ms-5 mb-1">Bezpečnostní analýza prvků infrastruktury</h5>*/}
                                    {/*<p className="ms-5 mb-1 fw-bold paragraph-medium">Bezpečnostní analýza prvků infrastruktury</p>*/}
                                    <p className="ms-3 ms-md-5 mb-1 paragraph-medium">{translation.list.no_1}</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}></Col>
                        <Col xs={12} sm={12} md={8} className="scroll-anim-1000">
                            <div className="style-zoom-3">
                                <div className="d-flex flex-row align-items-center mt-4 mb-4">
                                    <img src={square_plum} className="home-experts-img w-fit-content" alt={translation.alt.square_plum}></img>
                                    <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum">02</h4>
                                    <p className="ms-3 ms-md-5 mb-1 paragraph-medium">{translation.list.no_2}</p>
                                </div>
                            </div>
                            {/*<div className="scroll-anim-1000 style-zoom-3 text-end">
                                <div className="d-flex flex-row-reverse align-items-center mt-4 mb-4">
                                    <img src={square_plum} className="home-experts-img w-fit-content"></img>
                                    <h4 className="mb-0 me-3 w-fit-content font-divelit-plum">02</h4>
                                    <p className="me-3 me-md-5 mb-1 paragraph-medium">Integrace bezpečných hardwarových elementů</p>
                                </div>
                            </div>*/}
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}></Col>
                        <Col xs={12} sm={12} md={8} className="scroll-anim-1000">
                            <div className="style-zoom-3">
                                <div className="d-flex flex-row align-items-center mt-4 mb-4">
                                    <img src={square_plum} className="home-experts-img w-fit-content" alt={translation.alt.square_plum}></img>
                                    <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum">03</h4>
                                    <p className="ms-3 ms-md-5 mb-1 paragraph-medium">{translation.list.no_3}</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}></Col>
                        <Col xs={12} sm={12} md={8} className="scroll-anim-1000">
                            <div className="style-zoom-3">
                                <div className="d-flex flex-row align-items-center mt-4 mb-4">
                                    <img src={square_plum} className="home-experts-img w-fit-content" alt={translation.alt.square_plum}></img>
                                    <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum">04</h4>
                                    <p className="ms-3 ms-md-5 mb-1 paragraph-medium">{translation.list.no_4}</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={2}></Col>
                        <Col xs={12} sm={12} md={8} className="scroll-anim-1000">
                            <div className="style-zoom-3">
                                <div className="d-flex flex-row align-items-center mt-4 mb-4">
                                    <img src={square_plum} className="home-experts-img w-fit-content" alt={translation.alt.square_plum}></img>
                                    <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum">05</h4>
                                    <p className="ms-3 ms-md-5 mb-1 paragraph-medium">{translation.list.no_5}</p>
                                </div>
                            </div>
                        </Col>
                        <Col md={2}></Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 sepparator-md-w-95 mt-0 mb-0 scroll-anim-1000"></hr>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}></Col>
                        <Col xs={12} sm={12} md={8} className="scroll-anim-1000">
                            <div className="style-zoom-3">
                                <div className="d-flex flex-row align-items-center mt-4 mb-4">
                                    <img src={square_plum} className="home-experts-img w-fit-content" alt={translation.alt.square_plum}></img>
                                    <h4 className="mb-0 ms-3 w-fit-content font-divelit-plum">06</h4>
                                    <Col xs={12} sm={12} md={8} className="scroll-anim-1000">
                                        <p className="ms-3 ms-md-5 mb-1 paragraph-medium w-md-50">{translation.list.no_6}</p>
                                    </Col>
                                </div>
                            </div>
                        </Col>
                        <Col md={1}></Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
}

// utils
import React from "react";

// custom utils

// translations

// files

// page sections
import CybersecHero from "./components/cybersec-hero/cybersec-hero";
import CybersecContact from "./components/cybersec-contact/cybersec-contact";
import CybersecServices from "./components/cybersec-services/cybersec-services";

/**
 * CYBERSEC page
 * Handles whole CYBERSEC page with form - language, required input check, POST to the server as multipart/form-data
 * @returns HTML element for CYBERSEC page with 3 CybersecServices
 */
export default function Index() {

    /*const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }*/

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }
    
    return (
        <>
            <div className="fade-in-750">
                <CybersecHero></CybersecHero>
                <CybersecServices></CybersecServices>
                <CybersecContact></CybersecContact>
            </div>
        </>
    );
}

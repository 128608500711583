// utils
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";
import { isValidEmailAddress } from "../../../../../../utils/validationUtils"

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import jarda_krbec_g_05 from "../../../../../../files/images/pages/contact/jarda-krbec-kosile-circle-g-05.webp";
import loading_animation from "../../../../../../files/animations/pulsing-stripes-divelit-plum.svg";
import BtnBgPlum70Send from "../../../../../../components/btn/btn-bg-plum-70-send";

// page sections



/**
 * ManagementForm section
 * gives user a way how to contact us from our website
 * @returns HTML code with ManagementForm section for Management bookmark on CYBERSEC page
 */
export default function ManagementForm() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // Default form fields values with functions to change them
    const [inputMsg, setInputMsg] = useState("");
    const [inputName, setInputName] = useState("");
    const [inputEmail, setInputEmail] = useState("");
    const [inputCompany, setInputCompany] = useState("");
    const [inputPhone, setInputPhone] = useState("");
    const [checkBoxConsent, setCheckBoxConsent] = useState(false);
    const [formMsg, setFormMsg] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isErrorMsg, setIsErrorMsg] = useState(false);

    /**
     * On form submit is being checked whether user filled all the required fields or not and if given email is valid
     * @returns if POST is successfull GREEN message is displayed and all fields are deleted
     * @returns if POST was not successfull RED message is displayed and input remains untouched 
     */
    const handleSubmitForm = () => {
        // all <form> data to POST will be stored in this variable in multipart/form-data format
        const data = new FormData();

        console.log(inputMsg,inputName,inputEmail,inputCompany,inputPhone,checkBoxConsent)
        
        if(inputName === "")
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.name_error)
            return
        }
        if(inputEmail === "")
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.email_missing_error)
            return
        }
        else if(!isValidEmailAddress(inputEmail))
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.email_invalid_error)
            return
        }
        if(inputMsg === "")
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.empty_msg)
            return
        }
        if(!checkBoxConsent)
        {
            setIsErrorMsg(true);
            setFormMsg(translation.form.consent_error)
            return
        }
        setFormMsg("")
        setIsLoading(true);



        // adding all other inputs into 'data'
        data.append("cybersec_name", inputName);
        data.append("cybersec_email", inputEmail);
        data.append("cybersec_company", inputCompany);
        data.append("cybersec_phone", inputPhone);
        data.append("cybersec_msg", inputMsg);

        // if SUCCESS delete all inputs (otherwise let be)
        axios.post("/api/cybersec/form", data, {
            headers: {
                "Content-type": "multipart/form-data"
            },
            params: {
                lang: language
            }
          })
          .then(function (response) {
            setIsLoading(false);
            setIsErrorMsg(false);
            setFormMsg(translation.form.form_send_success);
            console.log(response);
            
            setInputMsg("");
            setInputName("");
            setInputEmail("");
            setInputCompany("");
            setInputPhone("");
            setCheckBoxConsent(false);

        })
        .catch(function (error) {
            setIsLoading(false);
            setIsErrorMsg(true);
            setFormMsg(translation.form.form_send_error);
            console.log(error);
        });
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    
    return (
        <>  
            <div className="">
                <div className="bg-divelit-grey-05" style={{marginTop: "15rem"}}>
                    <div className="pt-3 pt-lg-5"></div>
                    <Container className="pt-3 pt-lg-5 pb-3 pb-lg-5">
                        <Row className="row">
                            <Col xs={12} sm={12} md={7} className="">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={5} xl={5} className="p-lg-0 text-center scroll-anim-750">
                                        <img src={jarda_krbec_g_05} alt={translation.alt.profile_img} className="p-sm-5 p-md-0 p-xl-3 h-auto cybersec-training-form-jarda"></img>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={7} xl={7} className="scroll-anim-1000">
                                        <h3 className="">{translation.header}</h3>
                                    </Col>
                                </Row>
                                <div className="scroll-anim-1250">
                                    <p className="mt-1 mt-md-3 mt-lg-5 paragraph-small">{translation.main_paragraph}</p>
                                </div>
                                <div className="scroll-anim-1500">
                                    <p className="mt-1 mt-md-3 mt-lg-5 paragraph-small">{translation.write_email}
                                        <a className="link-href" href="mailto:project@divelit.cz">project@divelit.cz</a>
                                    </p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={5} className="" style={{marginTop: "0rem"}}>
                                <form id="cybersec_form" className="paragraph-small" method="post" action="" encType="multipart/form-data">
                                    <Row className="scroll-anim-750">
                                        <Col xs={12} sm={12} lg={6} className="">
                                            <input className="form-input-style" id="cybersec_name" type="text"
                                                name="cybersec_name" placeholder={translation.form.cybersec_name}
                                                value={inputName}
                                                onChange={(e) => setInputName(e.target.value)}
                                                />
                                        </Col>
                                        <Col xs={12} sm={12} lg={6} className="">
                                            <input className="mt-4 mt-lg-0 form-input-style" id="cybersec_email" type="text"
                                                name="cybersec_email" placeholder={translation.form.cybersec_email}
                                                value={inputEmail}
                                                onChange={(e) => setInputEmail(e.target.value)}
                                                />
                                        </Col>
                                    </Row>
                                    <Row className="scroll-anim-1000">
                                        <Col xs={12} sm={12} lg={6} className="mt-4 mt-md-4">
                                            <input className="form-input-style" id="cybersec_phone" type="text"
                                                name="cybersec_phone" placeholder={translation.form.cybersec_phone}
                                                value={inputPhone}
                                                onChange={(e) => setInputPhone(e.target.value)}
                                                />
                                        </Col>
                                        <Col xs={12} sm={12} lg={6} className="mt-4 mt-md-4">
                                            <input className="form-input-style" id="cybersec_company"
                                                type="text" name="cybersec_company" placeholder={translation.form.cybersec_company}
                                                value={inputCompany}
                                                onChange={(e) => setInputCompany(e.target.value)}
                                                />
                                        </Col>
                                    </Row>
                                    <Row className="scroll-anim-1250">
                                        <Col xs={12} sm={12} lg={12} className="mt-4 mt-md-4">
                                            <textarea className="form-msg-input-style contact-form-textarea" id="cybersec_msg"
                                            name="cybersec_msg" placeholder={translation.form.cybersec_msg}
                                                    value={inputMsg}
                                                    onChange={(e) => setInputMsg(e.target.value)}
                                                    />
                                        </Col>
                                    </Row>
                                    <Row className="scroll-anim-1500">
                                        <Col xs={12} sm={12} lg={12} className="mt-4 mt-md-4">
                                            <div className="d-flex flex-row">
                                                <label className="checkbox-container-plum">
                                                    <input type="checkbox" name="consent"
                                                checked={checkBoxConsent}
                                                onChange={(e) => setCheckBoxConsent(e.target.checked)}/>
                                                    <span className="checkmark-plum"></span>
                                                </label>
                                                <div className="" style={{lineHeight: "2rem"}}>
                                                {translation.form.consent.part1}<a className="link-href" href={process.env.PUBLIC_URL + '/pdf/' + translation.form.gdpr} target="_blank"  rel="noreferrer">{translation.form.consent.part2}</a><span className="paragraph-small">{translation.form.consent.part3}</span>
                                                </div>                                                    
                                            </div>                                            
                                            <p className="mt-4 paragraph-extra-small">{translation.form.required}</p>
                                        </Col>
                                    </Row>
                                    <Row className="scroll-anim-1750">
                                        <Col xs={"auto"} sm={"auto"} className="mt-3 mt-md-3">
                                            <BtnBgPlum70Send onClick={handleSubmitForm}></BtnBgPlum70Send>
                                        </Col>
                                        <Col xs={"auto"} sm={"auto"} className="mt-3 mt-md-3">
                                            <div className="form-message-box">
                                                {isLoading ?
                                                    <img src={loading_animation}
                                                        className="form-loading-animation" alt={translation.alt.loading_animation}/>
                                                    :
                                                    <span style={{color: isErrorMsg ? "red" : "green"}}>{formMsg}</span>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

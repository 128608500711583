// utils
import React, { useState } from "react";
import { useMatch } from "react-router-dom";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldHalved, faUserShield, faVirus, faUserSecret, faWifi, faHistory, faEnvelope, faKey, faLock, faDesktop, faGlobeAmericas, faUser, faUserGraduate, faExclamationTriangle, faQuestionCircle, faBuildingShield, faMobile, faMobilePhone, faM, faMobileAlt } from '@fortawesome/free-solid-svg-icons';

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";
import { isValidEmailAddress } from "../../../../../../utils/validationUtils"

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_lime from "../../../../files/icons/rency/square_lime.svg";
import square_black from "../../../../../../files/icons/rency/square_black.svg";



/**
 * TrainingTopics section
 * gives user a brief overview what he can learn from our training
 * @returns HTML code with TrainingTopics section for training bookmark on CYBERSEC page
 */
export default function TrainingTopics() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }


    
    return (
        <>  
            <Container className="pt-3 pt-md-5 pb-3 pb-md-5">
                <Container className="pt-3 pt-md-5 pb-3 pb-md-5">
                <Row>
                    <Col xs={12} sm={12} className="scroll-anim-750">
                        <h4 className="pt-3 pt-md-5 pb-3 pb-md-5 text-center">{translation.header}</h4>                      
                    </Col>
                </Row>
                <Row className="scroll-anim-1000">
                    <Col xs={12} sm={12} lg={1}></Col>
                    <Col xs={12} sm={12} lg={10} className="">
                        <Row className="">
                            <Col xs={12} sm={12} md={3} xl={2} className="mb-3 mb-md-0 p-3 p-md-3 bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border d-flex flex-column justify-content-center w-md-100" >
                                {/*<img className="h-100 w-100" alt={translation.alt.hw_competence} src={cybersec_img}/>*/}
                                <FontAwesomeIcon aria-hidden="true" icon={faBuildingShield} className="font-divelit-plum h-50 w-50 mx-auto"/>
                            </Col>
                            <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                            <Col xs={12} sm={12} md={8} className="p-3 p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                <div>
                                    <p className="paragraph-medium fw-bold">{translation.topics.no_01.header}</p>
                                    <ul className="paragraph-small mt-4 ms-md-3">
                                        <li className="mb-0">{translation.topics.no_01.questions.no_01}</li>
                                        <li className="pt-1 pt-lg-2 mb-0">{translation.topics.no_01.questions.no_02}</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} lg={1}></Col>
                </Row>
                <div className="d-none d-md-block scroll-anim-1250" style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>
                <Row className="scroll-anim-1000">
                    <Col xs={12} sm={12} lg={2}></Col>
                    <Col xs={12} sm={12} lg={10} className="">
                        <Row className="">
                            <Col xs={12} sm={12} md={8} className="p-3 p-md-4 order-3 order-md-1 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                <div>
                                    <p className="paragraph-medium fw-bold">{translation.topics.no_02.header}</p>
                                    <ul className="paragraph-small mt-4 ms-md-3">
                                        <li className="mb-0">{translation.topics.no_02.questions.no_01}</li>
                                        <li className="pt-1 pt-lg-2 mb-0">{translation.topics.no_02.questions.no_02}</li>
                                        <li className="pt-1 pt-lg-2 mb-0">{translation.topics.no_02.questions.no_03}</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={1} style={{width: "1rem"}} className="order-1 order-md-2"></Col>
                            <Col xs={12} sm={12} md={3} xl={2} className="mt-5 mt-md-0 mb-3 mb-md-0 p-3 p-md-3 order-2 order-md-3 bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border d-flex flex-column justify-content-center w-md-100 ms-auto me-0 ms-md-0" >
                                {/*<img className="h-100 w-100" alt={translation.alt.hw_competence} src={cybersec_img}/>*/}
                                <FontAwesomeIcon aria-hidden="true" icon={faKey} className="font-divelit-plum h-50 w-50 mx-auto"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="d-none d-md-block scroll-anim-1250" style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>
                <Row className="scroll-anim-1000">
                    <Col xs={12} sm={12} lg={1}></Col>
                    <Col xs={12} sm={12} lg={10} className="">
                        <Row className="">
                            <Col xs={12} sm={12} md={3} xl={2} className="mt-5 mt-md-0 mb-3 mb-md-0 p-3 p-md-3 bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border d-flex flex-column justify-content-center w-md-100" >
                                {/*<img className="h-100 w-100" alt={translation.alt.hw_competence} src={cybersec_img}/>*/}
                                <FontAwesomeIcon aria-hidden="true" icon={faEnvelope} className="font-divelit-plum h-50 w-50 mx-auto"/>
                            </Col>
                            <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                            <Col xs={12} sm={12} md={8} className="p-3 p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                <div>
                                    <p className="paragraph-medium fw-bold">{translation.topics.no_03.header}</p>
                                    <ul className="paragraph-small mt-4 ms-md-3">
                                        <li className="mb-0">{translation.topics.no_03.questions.no_01}</li>
                                        <li className="pt-1 pt-lg-2 mb-0">{translation.topics.no_03.questions.no_02}</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} lg={1}></Col>
                </Row>
                <div className="d-none d-md-block scroll-anim-1250" style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>
                <Row className="scroll-anim-1000">
                    <Col xs={12} sm={12} lg={2}></Col>
                    <Col xs={12} sm={12} lg={10} className="">
                        <Row className="">
                            <Col xs={12} sm={12} md={8} className="p-3 p-md-4 order-3 order-md-1 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                <div>
                                    <p className="paragraph-medium fw-bold">{translation.topics.no_04.header}</p>
                                    <ul className="paragraph-small mt-4 ms-md-3">
                                        <li className="mb-0">{translation.topics.no_04.questions.no_01}</li>
                                        <li className="pt-1 pt-lg-2 mb-0">{translation.topics.no_04.questions.no_02}</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={1} style={{width: "1rem"}} className="order-1 order-md-2"></Col>
                            <Col xs={12} sm={12} md={3} xl={2} className="mt-5 mt-md-0 mb-3 mb-md-0 p-3 p-md-3 order-2 order-md-3 bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border d-flex flex-column justify-content-center w-md-100 ms-auto ms-md-0 me-0" >
                                {/*<img className="h-100 w-100" alt={translation.alt.hw_competence} src={cybersec_img}/>*/}
                                <FontAwesomeIcon aria-hidden="true" icon={faDesktop} className="font-divelit-plum h-50 w-50 mx-auto"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="d-none d-md-block scroll-anim-1250" style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>
                <Row className="scroll-anim-1000">
                    <Col xs={12} sm={12} lg={1}></Col>
                    <Col xs={12} sm={12} lg={10} className="">
                        <Row className="">
                            <Col xs={12} sm={12} md={3} xl={2} className="mt-5 mt-md-0 mb-3 mb-md-0 p-3 p-md-3 bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border d-flex flex-column justify-content-center w-md-100" >
                                {/*<img className="h-100 w-100" alt={translation.alt.hw_competence} src={cybersec_img}/>*/}
                                <FontAwesomeIcon aria-hidden="true" icon={faUserSecret} className="font-divelit-plum h-50 w-50 mx-auto"/>
                            </Col>
                            <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                            <Col xs={12} sm={12} md={8} className="p-3 p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                <div>
                                    <p className="paragraph-medium fw-bold">{translation.topics.no_05.header}</p>
                                    <ul className="paragraph-small mt-4 ms-md-3">
                                        <li className="mb-0">{translation.topics.no_05.questions.no_01}</li>
                                        <li className="pt-1 pt-lg-2 mb-0">{translation.topics.no_05.questions.no_02}</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} lg={1}></Col>
                </Row>
                <div className="d-none d-md-block scroll-anim-1250" style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>
                <Row className="scroll-anim-1000">
                    <Col xs={12} sm={12} lg={2}></Col>
                    <Col xs={12} sm={12} lg={10} className="">
                        <Row className="">
                            <Col xs={12} sm={12} md={8} className="p-3 p-md-4 order-3 order-md-1 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                <div>
                                    <p className="paragraph-medium fw-bold">{translation.topics.no_06.header}</p>
                                    <ul className="paragraph-small mt-4 ms-md-3">
                                        <li className="mb-0">{translation.topics.no_06.questions.no_01}</li>
                                        <li className="pt-1 pt-lg-2 mb-0">{translation.topics.no_06.questions.no_02}</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={1} style={{width: "1rem"}} className="order-1 order-md-2"></Col>
                            <Col xs={12} sm={12} md={3} xl={2} className="mt-5 mt-md-0 mb-3 mb-md-0 p-3 p-md-3 order-2 order-md-3 bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border d-flex flex-column justify-content-center w-md-100 ms-auto ms-md-0 me-0" >
                                {/*<img className="h-100 w-100" alt={translation.alt.hw_competence} src={cybersec_img}/>*/}
                                <FontAwesomeIcon aria-hidden="true" icon={faMobileAlt} className="font-divelit-plum h-50 w-50 mx-auto"/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="d-none d-md-block scroll-anim-1250" style={{height: "100px", width: "8px", background: "var(--divelit-plum-20)", marginLeft: "auto", marginRight: "auto"}}></div>
                <Row className="scroll-anim-1000">
                    <Col xs={12} sm={12} lg={1}></Col>
                    <Col xs={12} sm={12} lg={10} className="">
                        <Row className="">
                            <Col xs={12} sm={12} md={3} xl={2} className="mt-5 mt-md-0 mb-3 mb-md-0 p-3 p-md-3 bg-divelit-plum-60 project-tile-box-shadow-divelit-grey-10 project-tile-border d-flex flex-column justify-content-center w-md-100" >
                                {/*<img className="h-100 w-100" alt={translation.alt.hw_competence} src={cybersec_img}/>*/}
                                <FontAwesomeIcon aria-hidden="true" icon={faUserShield} className="font-divelit-plum h-50 w-50 mx-auto"/>
                            </Col>
                            <Col xs={12} sm={12} md={1} style={{width: "1rem"}}></Col>
                            <Col xs={12} sm={12} md={8} className="p-3 p-md-4 project-tile-box-shadow-divelit-grey-10 project-tile-border" >
                                <div>
                                    <p className="paragraph-medium fw-bold">{translation.topics.no_07.header}</p>
                                    <ul className="paragraph-small mt-4 ms-md-3">
                                        <li className="mb-0">{translation.topics.no_07.questions.no_01}</li>
                                        <li className="pt-1 pt-lg-2 mb-0">{translation.topics.no_07.questions.no_02}</li>
                                    </ul>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} sm={12} lg={1}></Col>
                </Row>
            </Container>
            </Container>
        </>
    );
}

// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files


//page sections
import CybersecManagement from "../cybersec-management/cybersec-management";
import CybersecTraining from "../cybersec-training/cybersec-training";
import CybersecEmbedded from "../cybersec-embedded/cybersec-embedded";


/**
 * CybersecServices section
 * allows users to switch between displayed service management/training/embedded
 * @returns HTML code with CybersecServices section for CYBERSEC page
 */
export default function CybersecServices() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    function switchContent(e: any) {
        console.log("click");
        //console.log(e.target.id);
        //let btn = String(e.target.id);
        //let btnNum;
        //if(btn !== null) {
        //    btnNum = btn.match(/\d+/)![0]
        //}
        //console.log(btnNum);
        
        console.log("e.target", e.target.dataset.target);
        console.log("all elmenents with data-card attribute",document.querySelectorAll('[data-card]'));
        console.log("all elmenents with data-card attribute",document.querySelectorAll('[data-target]'));
        let btns = document.querySelectorAll('[data-target]');
        btns.forEach((btn) => {
            (btn as HTMLElement).style.color = 'var(--black)';
            (btn as HTMLElement).style.backgroundColor = 'var(--divelit-grey-05)';
        })


        let cards = document.querySelectorAll('[data-card]');
        
        cards.forEach((card) => {
            let cardHtmlElement = card as HTMLElement;
            //console.log((card as HTMLElement).dataset.card, e.target.dataset.target);
            
            //console.log(cardHtmlElement.dataset.card, e.target.dataset.target);

            if(cardHtmlElement.dataset.card === e.target.dataset.target) {
                console.log("GOT ONE", cardHtmlElement.dataset.card, e.target.dataset.target);
                console.log(cardHtmlElement);
                cardHtmlElement.style.display = 'block';
                //e.target.style.borderBottom = '8px solid blue'
                e.target.style.color = 'var(--white)';
                e.target.style.backgroundColor = "var(--divelit-plum)";
            }
            else {
                cardHtmlElement.style.display = 'none';

            }
        });
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }
    
    return (
        <>  
            <div className="bg-divelit-grey-05">
                <Container fluid={"md"} className="">
                    <Row>
                        <Col xs={12} sm={12} md={4} className="">
                            <a className="row cybersec-services-card-link-2 ps-2 pt-0 pt-md-4 pe-2 pb-1 pb-md-0 header-extra-small text-start text-md-center align-items-center h-100" href="#cybersecManagement" onClick={e => switchContent(e)} data-target="cybersecCard1" style={{color: "var(--white)", backgroundColor: "var(--divelit-plum)"}}>
                                <Col xs={9} sm={10} md={12} style={{pointerEvents: "none"}}>
                                    {translation.card1}
                                </Col>
                                <Col xs={3} sm={2} md={12} style={{pointerEvents: "none"}} className="text-center">
                                    <FontAwesomeIcon icon={faAngleDown} aria-hidden="true" className="font-divelit-grey-05 mt-3 mt-md-0 cybersec-services-down-icon-size"/>
                                </Col>
                            </a>
                        </Col>
                        <Col xs={12} sm={12} md={4} className="">
                            <a className="row cybersec-services-card-link-2 ps-2 pt-0 pt-md-4 pe-2 pb-1 pb-md-0 header-extra-small text-start text-md-center align-items-center h-100" href="#cybersecTraining" onClick={e => switchContent(e)} data-target="cybersecCard2">
                                <Col xs={9} sm={10} md={12} style={{pointerEvents: "none"}}>
                                    {translation.card2}
                                </Col>
                                <Col xs={3} sm={2} md={12} style={{pointerEvents: "none"}} className="text-center">
                                    <FontAwesomeIcon icon={faAngleDown} aria-hidden="true" className="font-divelit-grey-05 mt-3 mt-md-0 cybersec-services-down-icon-size"/>
                                </Col>
                            </a>
                        </Col>
                        <Col xs={12} sm={12} md={4} className="">
                            <a className="row cybersec-services-card-link-2 ps-2 pt-0 pt-md-4 pe-2 pb-1 pb-md-0 header-extra-small text-start text-md-center align-items-center h-100" href="#cybersecEmbedded" onClick={e => switchContent(e)} data-target="cybersecCard3">
                                <Col xs={9} sm={10} md={12} style={{pointerEvents: "none"}}>
                                    {translation.card3}
                                </Col>
                                <Col xs={3} sm={2} md={12} style={{pointerEvents: "none"}} className="text-center">
                                    <FontAwesomeIcon icon={faAngleDown} aria-hidden="true" className="font-divelit-grey-05 mt-3 mt-md-0 cybersec-services-down-icon-size"/>
                                </Col>
                            </a>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div data-card='cybersecCard1'>
                <CybersecManagement></CybersecManagement>
            </div>
            <div style={{display: 'none'}} data-card='cybersecCard2'>
                <CybersecTraining></CybersecTraining>
            </div>
            <div style={{display: 'none'}} data-card='cybersecCard3'>
                <CybersecEmbedded></CybersecEmbedded>
            </div>
        </>
    );
}
